import React, { Component } from 'react';
import $ from 'jquery';
import axios,{post,get} from 'axios';
import moment from 'moment';
import { ulid } from 'ulid'
import arrayMove from "./arrayMove";
import { CSVLink } from "react-csv";
import Html5QrcodePlugin  from "./Html5QrcodePlugin";
import ResultContainerPlugin from './ResultContainerPlugin';
import {Button, TextField, Table, TableSortLabel, TableBody, TableContainer, TableHead, TableRow,TableCell,styled,InputLabel,MenuItem,Checkbox,OutlinedInput,TablePagination,Select,FormControl,FormHelperText,Paper,Box,tableCellClasses} from '@mui/material';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { ReactSortable } from "react-sortablejs";

export default class TestManager extends Component{
	
	constructor(props) {
		super(props);
		this.state = {
		  parameters: [],
		};
	}
	
	componentDidMount() {
		this.getParameters()
	}
  
	getParameters=()=>{
		let ApiUrl = $('#ApiUrl').val();
		let that = this;
		let url = ApiUrl+'parameters';
		let data = [];
		axios.get(url)
        .then(response => {
            data = response.data;
			this.setState({parameters:data});
        })
	}

	handleSortEnd = () => {
		let that = this;
		setTimeout(function(){
				console.log('parameters->',that.state.parameters);
		}, 1000);	
	};

	render() {
		  const {parameters} = this.state;
		console.log('parameters->',parameters);
		  
		return (
		  <ReactSortable
			list={this.state.parameters}
			setList={(newState) => this.setState({ parameters: newState })}
			onEnd={this.handleSortEnd}
		  >
			{this.state.parameters.map((item) => (
			  <div key={item.id}>{item.name}</div>
			))}
		  </ReactSortable>
		);
	  }
}
