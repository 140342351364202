import React, { Component } from 'react';
import $ from 'jquery';
import axios,{post,get} from 'axios';
import { copyImageToClipboard } from 'copy-image-clipboard';
import html2canvas from 'html2canvas';
import moment from 'moment';

export default class QrGenerator extends Component {
	
	constructor(props){
        super(props)
		this.state ={
			apiKey:''
		}
    }
	
	componentDidMount() {
		this.getKey();
	}
	
	getValue=(event)=>{
		let name = event.target.name;
		let res = event.target.value;
		this.setState({[event.target.name]:event.target.value});
	}
	
	getKey=() => {
		let ApiUrl = $('#ApiUrl').val();
		let url = ApiUrl+'api-key';
		let data = [];
		axios.get(url)
        .then(response => {
			data = response.data;
			this.setState({apiKey:data.apikey});
		}).catch(error => {
			alert('error::'+ error);
		})
    }
	
	updateApiKey=()=>{
		let apiKey = this.state.apiKey;
		let ApiUrl = $('#ApiUrl').val();
		let url = ApiUrl+'update-api-key';
		let formData = new FormData();
		formData.append('apikey', apiKey);
		axios({
			method: 'POST',
			url: url,
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
		.then(response => {
			if(response.data){
				this.getKey();
				alert(response.data);
			}
		}).catch(error => {
			alert('error::'+ error);
		})
	}
	
	render() {
		const {apiKey} = this.state;
		let href = window.location.href.split('?')[0];
		return (
			<div className="container">
			<div className="row justify-content-center go-to-section mt-3 mb-4">
				<div className="col-md-12 text-center"><h4>API Key Manager</h4></div>
			</div>
			<div className="row justify-content-center">
				<div className="col-md-8 api-key-manager">
					<div className="key-label">API-Key:</div><div className="key-input"><input type="text" value={this.state.apiKey} name="apiKey" className="form-control" placeholder="Enter API-Key" onChange={this.getValue}></input></div><div className="key-btn"><button type="submit" className="btn btn-primary" onClick={this.updateApiKey}>Update</button></div>
				</div>
			</div>	
			</div>
		);
	}
}
