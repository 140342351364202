import React, { Component } from 'react';
import $ from 'jquery';
import axios,{post,get} from 'axios';
import moment from 'moment';
import { ulid } from 'ulid'
import arrayMove from "./arrayMove";
import { CSVLink } from "react-csv";
//import DatePicker from "react-datepicker";
//import "react-datepicker/dist/react-datepicker.css";
import Html5QrcodePlugin  from "./Html5QrcodePlugin";
import ResultContainerPlugin from './ResultContainerPlugin';
import {Button, TextField, Table, TableSortLabel, TableBody, TableContainer, TableHead, TableRow,TableCell,styled,InputLabel,MenuItem,Checkbox,OutlinedInput,TablePagination,Select,FormControl,FormHelperText,Paper,Box,tableCellClasses} from '@mui/material';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { ReactSortable } from "react-sortablejs";

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';


export default class FeeManager extends Component {
	
	constructor(props){
        super(props)
		this.state = { 
			country: '',
			states: [],
			cusTypeName: '',
			startDate:null,
			endDate:null,
			payers:[],
			parameters:[],
			payerStatesArray:[],
			templates:[],
			payerTypes:[],
			assignpayerTypes:[],
			payerLocation:[],
			payersDetails:[],
			templateHeaderParms:[],
			templateColumnParms:[],
			feeGuides:[],
			xlsxdata:[],
			feeGuidesFilter:[],
			addEditType:false,
			addEditParms:false,
			showTempArea:false,
			showTempGen:false,
			showLibrary:false,
			effectiveDate:dayjs(new Date()),
			eeffectiveDate:dayjs(new Date()),
			tempHeaders:new Map(),
			tempColumns:new Map(),
			page:0,
			rowsPerPage:10,
		};

    }
	
	componentDidMount() {
		this.loadScript();
		this.getPayers();
		this.getPayerTypes();
		this.getPayerLocations();
		this.getParameters();
		this.showGuideLibrary();
		
	}
	
	loadScript() {
		$(function() {
		  'use strict';
		  $('.js-menu-toggle').click(function(e) {
			var $this = $(this);
			if ( $('#wrapper').hasClass('toggled') ) {
				$('#wrapper').removeClass('toggled');
				$this.removeClass('active');
			} else {
				$('#wrapper').addClass('toggled');	
				$this.addClass('active');
			}
			e.preventDefault();
		  });
		});
	}
	
	getValue=(event)=>{
		let name = event.target.name;
		let value = event.target.value;
		let that = this;
		this.setState({[event.target.name]:event.target.value});
		
		if(name == 'wEditType'){
			let selectedTypeName = event.target.selectedOptions[0].text;
			this.setState({addEditType:false,wTypeName:selectedTypeName});
		}
		
		if(name == 'parms'){
			let selectedParmsName = event.target.selectedOptions[0].text;
			this.setState({addEditParms:false,wParmsName:selectedParmsName});
		}
		
		if(name == 'payer'){
			let payers = this.state.payers;
			let selectedTypeName = event.target.selectedOptions[0].text;
			this.setState({cusPayerName:selectedTypeName});
			payers.map((val, i) => {
				if(val['id'] == value){
					this.state.payerLocation.map((vl, i) => {
						if(vl['id'] == val['country']){
							this.setState({states: vl['states'] ? vl['states'] : [] });
						}
					});
					
					let payerTypes = this.state.payerTypes;
					let assignpayerTypes = [];
					if(payerTypes){
						payerTypes.map((tpe, i) => {
							if(tpe.assigned_countries){
								let ctr = tpe.assigned_countries.split(',');
								if(ctr.indexOf(val['country'].toString()) != -1){
									assignpayerTypes.push(tpe);
								}
							}	
						});
					}
					this.setState({payerCountry:val['country'],payerStates:val['states'],payerStatesArray:val['states'].split(','),selectpayerType:val['type'],assignpayerTypes});
				}
			});
			
			
		}
		
		if(name == 'selectedTemplate'){
			let templates = this.state.templates;
			let selectedtemplateName = event.target.selectedOptions[0].text;
			this.setState({templateName:selectedtemplateName});
			let tempHeaders = this.state.tempHeaders;
			let tempColumns = this.state.tempColumns;
			templates.map((val, i) => {
				if(val['id'] == value){
					let parameters = val.parameters;
					let headers = [];
					let columns = [];
					parameters.map((vl, i) => {
						if(vl['type'] == 'header'){
							headers.push(vl);
							tempHeaders.set(vl['id'],vl);
						}
						if(vl['type'] == 'column'){
							columns.push(vl);
							tempColumns.set(vl['id'],vl);
						}
					});
					this.setState({templateHeaderParms:headers,templateColumnParms:columns,tempHeaders,tempColumns});
				}
			});
		}
		
		if(name == 'selectedTemplate' && value == ''){
			this.setState({selectedTemplate:'',templateName:'',templateHeaderParms:[],templateColumnParms:[],tempHeaders:new Map(),tempColumns:new Map()});
		}
		
		if(name == 'filterCountry'){
			let countries = this.state.payerLocation
			countries.map((val, i) => {
				if(val['id'] == value){
					this.setState({states: val['states'] ? val['states'] : [] });
				}
			});
		}
		
		if(name == 'countryAss'){
			let payerTypes = this.state.payerTypes;
			let typeIds = [];
			if(payerTypes){
				payerTypes.map((val, i) => {
					if(val.assigned_countries){
						let ctr = val.assigned_countries.split(',');
						if(ctr.indexOf(value) != -1){
							this.setState({[value+'-'+val.id]:val.id});
						}
					}	
				});
			}
		}
		
		setTimeout(function(){
			that.dataFilter();
		}, 1000);
	}
	
	selectCountry=(event)=>{
		let name = event.target.name;
		let res = event.target.value;
		this.setState({[event.target.name]:event.target.value});
		let selectedTypeName = event.target.selectedOptions[0].text;
		let countries = this.state.payerLocation
		//console.log('countries->',countries);
		countries.map((val, i) => {
			if(val['id'] == res){
				this.setState({states: val['states'] ? val['states'] : [] });
			}
		});
		
		let payerTypes = this.state.payerTypes;
		let assignpayerTypes = [];
		if(payerTypes){
			payerTypes.map((val, i) => {
				if(val.assigned_countries){
					let ctr = val.assigned_countries.split(',');
					if(ctr.indexOf(res) != -1){
						assignpayerTypes.push(val);
					}
				}	
			});
		}

		
		this.setState({addEditLoc:false,wLocName:selectedTypeName,assignpayerTypes});
	}
	
	/* selectCountryAss(val) {
		this.setState({ countryAss: val });
	} */

	selectRegion=(event)=>{
		let name = event.target.name;
		let res = event.target.value;
		this.setState({[event.target.name]:event.target.value});
		let selectedStateName = event.target.selectedOptions[0].text;
		res = Array.from(event.target.selectedOptions, (item) => item.value).join();
		let newArray = name+'Array';
		this.setState({[event.target.name]:res,[newArray]:Array.from(event.target.selectedOptions, (item) => item.value),addEditState:false,wStateName:selectedStateName});
		this.setState({addEditState:false,wStateName:selectedStateName});
	}
	
	getPayers=()=>{
		let ApiUrl = $('#ApiUrl').val();
		let that = this;
		let url = ApiUrl+'payers';
		let data = [];
		axios.get(url)
        .then(response => {
            data = response.data;
			this.setState({payers:data});
        })
	}
	
	getParameters=()=>{
		let ApiUrl = $('#ApiUrl').val();
		let that = this;
		let url = ApiUrl+'parameters';
		let data = [];
		axios.get(url)
        .then(response => {
            data = response.data;
			this.setState({parameters:data});
        })
	}
	
	getPayerTypes=()=>{
		let ApiUrl = $('#ApiUrl').val();
		let that = this;
		let url = ApiUrl+'payer-types';
		let data = [];
		axios.get(url)
        .then(response => {
            data = response.data;
			this.setState({payerTypes:data,wEditType:''});
        })
	}
	
	getPayerLocations=()=>{
		let ApiUrl = $('#ApiUrl').val();
		let that = this;
		let url = ApiUrl+'countries';
		let data = [];
		axios.get(url)
        .then(response => {
            data = response.data;
			this.setState({payerLocation:data});
			let payerLoc = this.state.payerLoc;
			if(payerLoc){
			data.map((val, i) => {
				if(val['id'] == payerLoc){
					this.setState({states: val['states'] ? val['states'] : [] });
				}
			});
			}
        })
	}
	
	addPayerType=() => {
		this.setState({addEditType:true,cusTypeName:'',editTypeId:''});
	}
	
	editPayerType=() => {
		let typeName = this.state.wTypeName;
		this.setState({addEditType:true,cusTypeName:typeName,editTypeId:this.state.wEditType});
	}
	
	addPayerLoc=() => {
		this.setState({addEditLoc:true,cusCntryName:'',editLocId:'',cusStateName:''});
	}
	
	editPayerLoc=() => {
		let wLocName = this.state.wLocName;
		this.setState({addEditLoc:true,cusCntryName:wLocName,editLocId:this.state.payerLoc,cusStateName:''});
	}
	
	addUpdateType=() => {
		
		let cusTypeName = this.state.cusTypeName;
		let editTypeId = this.state.editTypeId;
		if(cusTypeName || editTypeId){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'update-payer-type';
			let formData = new FormData();
			formData.append('id', editTypeId);
			formData.append('type', cusTypeName);
			axios({
				method: 'POST',
				url: url,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				if(response.data){
					this.getPayerTypes();
					this.setState({cusTypeName:'',addEditType:false,wTypeName:'',editTypeId:'',wEditType:''});
					$('.w-edit-category').val('');
					alert(response.data);
				}
			}).catch(error => {
				alert('error::'+ error);
			})
		}
	}
	
	deleteType=(id) => {
		if(id){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'delete-payer-type/'+id;
			axios({
				method: 'delete',
				url: url,
			})
			.then(response => {
				if(response.data){
					this.getPayerTypes();
					alert(response.data);
				}
			}).catch(error => {
				alert('error::'+ error);
			})
		}else{
			alert('Please select payer type!');
		}
	}
	
	setWorkflowList=() => {
	}
	
	addPayerLocState=() => {
		this.setState({editLocId:this.state.payerLoc,addEditState:true,cusStateName:'',editStateId:''});
	}
	
	editPayerLocState=() => {
		let wStateName = this.state.wStateName;
		this.setState({editLocId:this.state.payerLoc,addEditState:true,cusStateName:wStateName,editStateId:this.state.locState});
	}
	
	deleteCountry=(id) => {
		if(id){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'delete-country/'+id;
			axios({
				method: 'delete',
				url: url,
			})
			.then(response => {
				if(response.data){
					this.getPayerLocations();
					alert(response.data);
				}
			}).catch(error => {
				alert('error::'+ error);
			})
		}else{
			alert('Please select Country!');
		}
	}
	
	deleteState=(id) => {
		if(id){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'delete-state/'+id;
			axios({
				method: 'delete',
				url: url,
			})
			.then(response => {
				if(response.data){
					this.getPayerLocations();
					alert(response.data);
				}
			}).catch(error => {
				alert('error::'+ error);
			})
		}else{
			alert('Please select State!');
		}
	}
	
	addUpdateLocation=() => {
		
		let cusCntryName = this.state.cusCntryName;
		let editLocId = this.state.editLocId;
		
		let cusStateName = this.state.cusStateName;
		let editStateId = this.state.editStateId;
		
		if(cusCntryName || cusStateName){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'update-payer-location';
			let formData = new FormData();
			if(cusStateName){
				formData.append('country_id', editLocId);
				formData.append('state_id', editStateId ? editStateId : '');
				formData.append('name', cusStateName);
			}else{
				formData.append('id', editLocId);
				formData.append('name', cusCntryName);
			}
			
			axios({
				method: 'POST',
				url: url,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				if(response.data){
					this.setState({cusCntryName:'',addEditLoc:false,wLocName:'',editLocId:'',addEditState:false,locState:'',cusStateName:'',editStateId:''});
					this.getPayerLocations();
					alert(response.data);
				}
			}).catch(error => {
				alert('error::'+ error);
			})
		}
	}
	
	addUpdatePayer=() => {
		let payer = this.state.payer;
		let payerCountry = this.state.payerCountry;
		let payerStates = this.state.payerStates.split(',');
		//let payerStatesArray = this.state.payerStates.split(',');
		let selectpayerType = this.state.selectpayerType;
		let cusPayerName = this.state.cusPayerName;
		
		if(cusPayerName){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'update-payer';
			let formData = new FormData();
			if(payer){
				formData.append('id', payer);
			}
			formData.append('name', cusPayerName);
			formData.append('country', payerCountry);
			formData.append('states', payerStates);
			formData.append('type', selectpayerType);
			
			axios({
				method: 'POST',
				url: url,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				if(response.data){
					this.setState({payer:'',payerCountry:'',payerStates:'',payerStatesArray:[],selectpayerType:'',cusPayerName:''});
					this.getPayers();
					alert(response.data);
				}
			}).catch(error => {
				alert('error::'+ error);
			})
		}
	}
	
	addParms=() => {
		this.setState({addEditParms:true,cusParmsName:'',editParmsId:''});
	}
	
	editParms=() => {
		let wParmsName = this.state.wParmsName;
		this.setState({addEditParms:true,cusParmsName:wParmsName,editParmsId:this.state.parms});
	}
	
	addUpdateParms=() => {
		this.updateParmsOrder();
		let cusParmsName = this.state.cusParmsName;
		let editParmsId = this.state.editParmsId;
		if(cusParmsName || editParmsId){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'update-parameter';
			let formData = new FormData();
			formData.append('id', editParmsId);
			formData.append('name', cusParmsName);
			formData.append('parameters', this.state.parameters);
			axios({
				method: 'POST',
				url: url,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				if(response.data){
					this.getParameters();
					this.setState({parms:'',cusParmsName:'',addEditParms:false,wParmsName:'',editParmsId:''});
					alert(response.data);
				}
			}).catch(error => {
				alert('error::'+ error);
			})
		}
	}
	
	updateParmsOrder=() => {
		let parameters = this.state.parameters;
		let newArray = [];
		parameters.map((val, i) => {
			newArray.push(val['id']);
		})
		let ids = newArray.join();
		if(parameters){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'update-parm-order';
			let formData = new FormData();
			formData.append('parameters', ids);
			axios({
				method: 'POST',
				url: url,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				if(response.data){
					this.getParameters();
				}
			}).catch(error => {
				alert('error::'+ error);
			})
		}
	}
	
	deleteParms=(id) => {
		if(id){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'delete-parms/'+id;
			axios({
				method: 'delete',
				url: url,
			})
			.then(response => {
				if(response.data){
					this.getParameters();
					alert(response.data);
				}
			}).catch(error => {
				alert('error::'+ error);
			})
		}else{
			alert('Please select the Parameter!');
		}
	}
	
	onSortEnd = ({oldIndex, newIndex}) => {
		this.setState(({parameters}) => ({
		    parameters: arrayMove(parameters, oldIndex, newIndex),
		}));
	};
	
	/* onSortEndHeader = ({oldIndex, newIndex}) => {
		this.setState(({templateHeaderParms}) => ({
		    templateHeaderParms: arrayMove(templateHeaderParms, oldIndex, newIndex),
		}));
		
		let that = this;
		setTimeout(function(){
			let move = [];
			that.state.templateHeaderParms.map((val, i) => {
				val.pos = i+1;
				move.push(val);
			});
			that.setState({templateHeaderParms:move});
		}, 1000);
		 
	}; */
	
	onSortEndHeader = () => {
		let that = this;
		setTimeout(function(){
			let move = [];
			that.state.templateHeaderParms.map((val, i) => {
				val.pos = i+1;
				move.push(val);
			});
			that.setState({templateHeaderParms:move});
		}, 2000);
	};
	
	/* onSortEndColumn = ({oldIndex, newIndex}) => {
		this.setState(({templateColumnParms}) => ({
		    templateColumnParms: arrayMove(templateColumnParms, oldIndex, newIndex),
		}));
		
		let that = this;
		setTimeout(function(){
			let move2 = [];
			that.state.templateColumnParms.map((val, i) => {
				val.pos = i+1;
				move2.push(val);
			});
			that.setState({templateColumnParms:move2});
		}, 1000);
	}; */
	
	onSortEndColumn = () => {
		let that = this;
		setTimeout(function(){
			let move = [];
			that.state.templateColumnParms.map((val, i) => {
				val.pos = i+1;
				move.push(val);
			});
			that.setState({templateColumnParms:move});
		}, 2000);
	};
	
	templateManager=() => {
		this.getTemplates();
		this.setState({showTempArea:true,showTempGen:false,showLibrary:false});
	}
	
	setTempHeaders = (event) => {
		let parmId = parseInt(event.target.id);
		let taskName = event.target.name;
		let length = this.state.templateHeaderParms.length;
		let tempHeaders = this.state.tempHeaders;
		if(event.target.checked){
			this.state.parameters.map((val, i) => {
				if(val.id == parmId){
					val.pos = length+1;
					val.type = 'header';
					tempHeaders.set(parmId,val); 
				}
			});
		}else{
			tempHeaders.delete(parmId);
		}
		
		this.setState({tempHeaders:tempHeaders});
		
		let opTasks = Object.fromEntries(tempHeaders.entries());
		let templateParms = [];
		
		for (let prop in opTasks) {
			templateParms.push(opTasks[prop]);
		}
		templateParms = templateParms.filter(function(x) {
			 return x !== '';
		});
		
		templateParms.sort(function(a, b){
			return parseInt(a.pos)- parseInt(b.pos);
		});
		
		//console.log('templateParms->',templateParms);
		
		templateParms.map((val, i) => {
			val.pos = i+1;
		});
		this.setState({templateHeaderParms:templateParms});
	}
	
	setTempColumns = (event) => {
		let parmId = parseInt(event.target.id);
		let length = this.state.templateColumnParms.length;
		let tempColumns = this.state.tempColumns;
		
		if(event.target.checked){
			this.state.parameters.map((val, i) => {
				if(val.id == parmId){
					val.pos = length+1;
					val.type = 'column';
					tempColumns.set(parmId,val);
				}
			});
		}else{
			tempColumns.delete(parmId);
		}
		
		this.setState({tempColumns:tempColumns});
		
		let opParms = Object.fromEntries(tempColumns.entries());
		let templateParms2 = [];
		
		for (let prop in opParms) {
			templateParms2.push(opParms[prop]);
		}
		templateParms2 = templateParms2.filter(function(x) {
			 return x !== '';
		});
		
		templateParms2.sort(function(a, b){
			return parseInt(a.pos)- parseInt(b.pos);
		});
		
		templateParms2.map((val, i) => {
			val.pos = i+1;
		});
		
		this.setState({templateColumnParms:templateParms2});
	}
	
	getTemplates=()=>{
		let ApiUrl = $('#ApiUrl').val();
		let that = this;
		let url = ApiUrl+'templates';
		let data = [];
		axios.get(url)
        .then(response => {
            data = response.data;
			this.setState({templates:data});
        })
	}
	
	saveGuideTemplate=() => {
		let selectedTemplate = this.state.selectedTemplate;
		let templateName = this.state.templateName;
		let templateHeaderParms = this.state.templateHeaderParms;
		let templateColumnParms = this.state.templateColumnParms;
		
		if(templateName || selectedTemplate){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'update-template';
			let formData = new FormData();
			
			if(selectedTemplate){
				formData.append('id', selectedTemplate);
			}
			let params = templateHeaderParms.concat(templateColumnParms);
			formData.append('name', templateName);
			formData.append('params', JSON.stringify(params));
			axios({
				method: 'POST',
				url: url,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				if(response.data){
					this.getTemplates();
					//this.setState({parms:'',cusParmsName:'',addEditParms:false,wParmsName:'',editParmsId:''});
					alert(response.data);
				}
			}).catch(error => {
				alert('error::'+ error);
			})
		}
	}
	
	templateGenerator=() => {
		this.getTemplates();
		this.getNewGuide();
		this.setState({showTempGen:true,showTempArea:false,showLibrary:false});
	}
	
	showGuideLibrary=() => {
		this.getFeeGuides();
		this.setState({showLibrary:true,showTempGen:false,showTempArea:false});
	}
	
	getFeeGuides=()=>{
		
		let role = localStorage.getItem('role');
		let user_id = localStorage.getItem('user_id');
		
		let ApiUrl = $('#ApiUrl').val();
		let url = ApiUrl+'fee-guides';
		let data = [];
		axios.get(url)
        .then(response => {
            data = response.data;
			let feeGuides = [];
			if(data){
				data.map((val, i) => {
					
					if((role == 'FeeUser' && val['user_id'] == user_id) || (val['user_role'] == 'SuperAdmin' || val['user_role'] == 'Admin')){
						feeGuides.push(val);
					}
				});
			}
			this.setState({feeGuides:feeGuides});
			let that =this;
			setTimeout(function(){
				that.getTemplates();
				that.dataFilter();
			}, 1000);
        })
	}
	
	dataFilter=() => {
		let feeGuidesFilter = [];
		let that =this;
		this.state.feeGuides.map(function(row,i) {
			
			if(that.state.filterCountry && that.state.filterCountry != row.payer.country){
				return false;
			}
			
			let states = row.payer.states.split(',');
			let filterState = that.state.filterState;
			if(that.state.filterState && states.indexOf(filterState.toString()) == -1 ){
				return false;
			}
			
			if(that.state.filterPayer && that.state.filterPayer != row.payer.id){
				return false;
			}
			
			if(that.state.filterPayerType && that.state.filterPayerType != row.payer.type){
				return false;
			}
			
			if(that.state.filterYear && that.state.filterYear != row.fee_guide_year){
				return false;
			}
			
			if(that.state.filterStatus && that.state.filterStatus != row.status){
				return false;
			}
			
			if(that.state.filterUploaded && that.state.filterUploaded != row.uploaded){
				return false;
			}
			
			let fee_guide_date = dayjs(row.fee_guide_date);
			
			if(that.state.startDate){
				let sDate = dayjs(that.state.startDate);
				if(fee_guide_date < sDate){
					return false;
				}
			}
			
			if(that.state.endDate){
				let eDate = dayjs(that.state.endDate);
				if(fee_guide_date > eDate){
					return false;
				}
			}

			feeGuidesFilter.push(row);
		});
		
		this.setState({feeGuidesFilter,page:this.state.page});
	}
	
	handleChangeStart = date => {
        this.setState({
          effectiveDate: date,date
        });
    }
	
	handleChangeStartv3 = date => {
        this.setState({
          eeffectiveDate: date,date
        });
    }
	
	getPayerDetails=(event)=>{
		let name = event.target.name;
		let value = event.target.value;
		this.setState({[event.target.name]:event.target.value});
		let ApiUrl = $('#ApiUrl').val();
		let that = this;
		let url = ApiUrl+'payer-details/'+value;
		let data = [];
		axios.get(url)
        .then(response => {
            data = response.data;
			this.setState({payersDetails:data});
        })
	}
	
	getNewGuide=()=>{
		let ApiUrl = $('#ApiUrl').val();
		let that = this;
		let url = ApiUrl+'generate-guide';
		let data = [];
		axios.get(url)
        .then(response => {
           data = response.data;
			this.setState({newGuide:data});
        })
	}
	
	downloadGuideTemplate=() => {
		let sTemplate = this.state.sTemplate;
		let guideYear = this.state.guideYear;
		let effectiveDate = this.state.effectiveDate;
		let namefeeGuide = this.state.namefeeGuide;
		let sPayer = this.state.sPayer;
		let templates = this.state.templates;
		let newGuide = this.state.newGuide;
		let payersDetails = this.state.payersDetails;
		
		let tempHeaders = [];
		let tempColumns = [];
		templates.map((val, i) => {
			if(val['id'] == sTemplate){
				let parameters = val.parameters;
				parameters.map((vl, i) => {
					if(vl['type'] == 'header'){
						tempHeaders.push(vl['name']);
					}
					if(vl['type'] == 'column'){
						tempColumns.push(vl['name']);
					}
				});
			}
		});

		let headers = [];
		headers.push(["Payer Name",payersDetails['name']]);
		if(tempHeaders.length > 0){
			tempHeaders.map((hr, i) => {
				if(hr == 'Country'){
					let str = ["Country",payersDetails['country_name']];
					headers.push(str);
				}
				if(hr == 'State / Province'){
					let str = ["State / Province",payersDetails['states_name']];
					headers.push(str);
				}
				if(hr == 'Payer Type'){
					let str = ["Payer Type",payersDetails['payer_type']];
					headers.push(str);
				}
			});
		}
		const saveArray = {
			"fee_guide": newGuide,
			"fee_guide_name": namefeeGuide,
			"template_id": sTemplate,
			"payer_id": sPayer,
			"fee_guide_year": guideYear,
			"fee_guide_date": moment(effectiveDate).format('YYYY-MM-DD'),
		}
		
		let fileName = newGuide;
		let fieldData = [
			["Fee Guide Year",guideYear],
			["Fee Guide effective Date",moment(effectiveDate).format('MM-DD-YYYY')],
			["Active",'No'],
			["Fee Guide Name",namefeeGuide],
			["Fee Guide ID",newGuide],
			["",""],
			tempColumns,
		];
		
		const tempData = headers.concat(fieldData);
		this.newDownloadExcel(tempData,fileName,namefeeGuide);
		let that = this;
		setTimeout(function(){
			that.saveFeeGuide(saveArray);
			that.setState({sTemplate:'',guideYear:'',effectiveDate:'',namefeeGuide:'',sPayer:''});
		}, 2000);
	}
	
	newDownloadExcel=(tempData,fileName,namefeeGuide) => {
		
		let dateOne = new Date();
		
		let sheettabName = fileName+'-'+moment(dateOne).format('MMDDYYYY');
		let sheetName = fileName+'-'+namefeeGuide+'-'+moment(dateOne).format('MMDDYYYY');
		if(tempData){
			const worksheet = XLSX.utils.aoa_to_sheet(tempData);
			const workbook = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(workbook, worksheet, sheettabName);
			const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
			const blob = new Blob([excelBuffer], {type: 'application/octet-stream'});
			saveAs(blob, `${sheetName}.xlsx`);
		}
    }
	
	saveFeeGuide=(saveArray) => {
		let ApiUrl = $('#ApiUrl').val();
		let url = ApiUrl+'save-fee-guide';
		let formData = new FormData();
		formData.append('fee_guide', saveArray.fee_guide);
		formData.append('fee_guide_name', saveArray.fee_guide_name);
		formData.append('template_id', saveArray.template_id);
		formData.append('payer_id', saveArray.payer_id);
		formData.append('fee_guide_year', saveArray.fee_guide_year);
		formData.append('fee_guide_date', saveArray.fee_guide_date);
		axios({
			method: 'POST',
			url: url,
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
		.then(response => {
			alert('The fee Guide saved successfully!');
		}).catch(error => {
			alert('error::'+ error);
		})
		
	}
	
	handleChangePage = (event: unknown, newPage: number) => {
		this.setState({page:newPage});
	};

	handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({rowsPerPage:event.target.value,page:0});
	};
	
	handleChangeStartv2 = date => {
		this.setState({startDate: date});
		
		let that = this;
		setTimeout(function(){
			that.dataFilter();
		}, 1000);
    }
	
	handleChangeEnd = date => {
        this.setState({
          endDate: date,date
        });
		
		let that = this;
		setTimeout(function(){
			that.dataFilter();
		}, 1000);
    }
	
	uploadFeeGuideTemplate=() => {

		alert('Function in progress!');
		
		
	}
	
	editFeeGuide=(val) => {
		this.getTemplates();
		let efeeId = val.id;
		let esTemplate = val.template_id;
		let eguideYear = val.fee_guide_year;
		let eeffectiveDate = dayjs(new Date(val.fee_guide_date));
		//let eeffectiveDate = new Date(val.fee_guide_date);
		let esPayer = val.payer_id;
		let enamefeeGuide = val.fee_guide_name;
		let eStatus = val.status;
		this.setState({efeeId,esTemplate,eguideYear,eeffectiveDate,esPayer,enamefeeGuide,eStatus});
		$('#editFeeGuide').modal('show');
	}
	
	updateFeeGuide=() => {
		let efeeId = this.state.efeeId;
		let esTemplate = this.state.esTemplate;
		let eguideYear = this.state.eguideYear;
		let eeffectiveDate = this.state.eeffectiveDate;
		let esPayer = this.state.esPayer;
		let enamefeeGuide = this.state.enamefeeGuide;
		let eStatus = this.state.eStatus;
		
		if(efeeId){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'update-fee-guide';
			let uid = ulid();
			let formData = new FormData();
			formData.append('id', efeeId);
			formData.append('template_id', esTemplate);
			formData.append('payer_id', esPayer);
			formData.append('fee_guide_name', enamefeeGuide);
			formData.append('fee_guide_year', eguideYear);
			formData.append('fee_guide_date', moment(eeffectiveDate).format('YYYY-MM-DD'));
			formData.append('status', eStatus);
			axios({
				method: 'POST',
				url: url,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				if(response.data){
					this.getFeeGuides();
					this.setState({efeeId:'',esTemplate:'',eguideYear:'',eeffectiveDate:'',esPayer:'',enamefeeGuide:'',eStatus:''});
					alert(response.data);
					$('#editFeeGuide').modal('hide');
				}
			}).catch(error => {
				alert('error::'+ error);
			})
		}
		
	}
	
	downloadBlankTemplate=(row)=>{
		let sTemplate = row.template_id;
		let guideYear = row.fee_guide_year;
		let effectiveDate = row.fee_guide_date;
		let namefeeGuide = row.fee_guide_name;
		let sPayer = row.payer_id;
		let templates = this.state.templates;
		let newGuide = row.fee_guide;
		let payersDetails = row.payer;
		let status = row.status;
		let statusTxt = 'No';
		
		if(status == 'Y'){
			statusTxt = 'Yes';
		}
		
		let tempHeaders = [];
		let tempColumns = [];
		templates.map((val, i) => {
			if(val['id'] == sTemplate){
				let parameters = val.parameters;
				parameters.map((vl, i) => {
					if(vl['type'] == 'header'){
						tempHeaders.push(vl['name']);
					}
					if(vl['type'] == 'column'){
						tempColumns.push(vl['name']);
					}
				});
			}
		});

		let headers = [];
		headers.push(["Payer Name",payersDetails['name']]);
		if(tempHeaders.length > 0){
			tempHeaders.map((hr, i) => {
				if(hr == 'Country'){
					let str = ["Country",payersDetails['country_name']];
					headers.push(str);
				}
				if(hr == 'State / Province'){
					let str = ["State / Province",payersDetails['states_name']];
					headers.push(str);
				}
				if(hr == 'Payer Type'){
					let str = ["Payer Type",payersDetails['payer_type']];
					headers.push(str);
				}
			});
		}

		let fileName = newGuide;
		let fieldData = [
			["Fee Guide Year",guideYear],
			["Fee Guide effective Date",moment(effectiveDate).format('MM-DD-YYYY')],
			["Active",statusTxt],
			["Fee Guide Name",namefeeGuide],
			["Fee Guide ID",newGuide],
			["",""],
			tempColumns,
		];
		
		const tempData = headers.concat(fieldData);
		this.newDownloadExcel(tempData,fileName,namefeeGuide);
	}
	
	downloadComplatedTemplate=(row)=>{
		let sTemplate = row.template_id;
		let guideYear = row.fee_guide_year;
		let effectiveDate = row.fee_guide_date;
		let namefeeGuide = row.fee_guide_name;
		let sPayer = row.payer_id;
		let templates = this.state.templates;
		let newGuide = row.fee_guide;
		let payer_name = row.payer_name;
		let country_name = row.country_name;
		let payer_type = row.payer_type;
		let states_name = row.payer.states_name;
		let status = row.status;
		let statusTxt = 'No';
		
		if(status == 'Y'){
			statusTxt = 'Yes';
		}
		let tempColumns = JSON.parse(row.details.data);
		let fileName = newGuide;
		let fieldData = [
			["Payer Name",payer_name],
			["Country",country_name],
			["State / Province",states_name],
			["Payer Type",payer_type],
			["Fee Guide Year",guideYear],
			["Fee Guide effective Date",moment(effectiveDate).format('MM-DD-YYYY')],
			["Active",statusTxt],
			["Fee Guide Name",namefeeGuide],
			["Fee Guide ID",newGuide],
			["",""],
		];
		let tempData = fieldData.concat(tempColumns);
		this.newDownloadExcel(tempData,fileName,namefeeGuide);
	}
	
	copyTemplate=(row)=>{
		this.getNewGuide();
		let that = this;
		let date = new Date().toLocaleString("en-US", {timeZone: "America/New_York"});
		setTimeout(function(){
			let sTemplate = row.template_id;
			let guideYear = row.fee_guide_year;
			let effectiveDate = row.fee_guide_date;
			let namefeeGuide = 'Copy-'+row.fee_guide_name+'-'+moment(date).format('MMDDYYYY');
			let sPayer = row.payer_id;
			let newGuide = that.state.newGuide;
			let saveArray = {
				"fee_guide": newGuide,
				"fee_guide_name": namefeeGuide,
				"template_id": sTemplate,
				"payer_id": sPayer,
				"fee_guide_year": guideYear,
				"fee_guide_date": moment(effectiveDate).format('YYYY-MM-DD'),
			}
			that.saveFeeGuideV2(saveArray);
			
			let tempColumns = JSON.parse(row.details.data);
			let user_id = localStorage.getItem('user_id');
			let jsonData = {
				"guide_id": newGuide,
				"user_id": user_id,
				"paramters": tempColumns,
			}
			if(jsonData){
				that.saveCompleteDataV2(jsonData);
			}
		}, 2000);
	}
	
	saveFeeGuideV2=(saveArray) => {
		let ApiUrl = $('#ApiUrl').val();
		let url = ApiUrl+'save-fee-guide';
		let formData = new FormData();
		formData.append('fee_guide', saveArray.fee_guide);
		formData.append('fee_guide_name', saveArray.fee_guide_name);
		formData.append('template_id', saveArray.template_id);
		formData.append('payer_id', saveArray.payer_id);
		formData.append('fee_guide_year', saveArray.fee_guide_year);
		formData.append('fee_guide_date', saveArray.fee_guide_date);
		axios({
			method: 'POST',
			url: url,
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
		.then(response => {
			alert('The fee Guide copied successfully!');
		}).catch(error => {
			alert('error::'+ error);
		})
		
	}
	
	saveCompleteDataV2=(jsonData)=>{
		let ApiUrl = $('#ApiUrl').val();
		let url = ApiUrl+'save-guide-data';
		axios({
			method: 'POST',
			url:url,
			data:JSON.stringify(jsonData),
			headers: {
				'Content-Type': 'application/json',
			}
		})
		.then(response => {
			this.getFeeGuides();
		}).catch(error => {
			console.log('error::', error);
		})
	}
	
	deleteFeeGuide=(id)=>{
		if(id){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'delete-fee-guide/'+id;
			axios({
				method: 'delete',
				url: url,
			})
			.then(response => {
				this.getFeeGuides();
			}).catch(error => {
				alert('error::'+ error);
			})
		}
	}
	
	changeStatus=(id,name)=>{
		if(id){
			let ApiUrl = $('#ApiUrl').val();
			let active = 'N';
			if($("input[name='"+name+"']").prop("checked") == true){
				active = 'Y';
				this.setState({[name]:'Y'});
			}
			let url = ApiUrl+'fee-guide-status';
			let data = [];
			let formData = new FormData();
			formData.append('id', id);
			formData.append('status', active);
			axios({
				method: 'POST',
				url: url,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {	
			})
		}
	}
	
	setAssignedTypes=(event)=>{
		let name = event.target.name;
		let val = event.target.value;
		let isChecked = event.target.checked;
		let countryAss = this.state.countryAss;
		if(isChecked && countryAss){
			this.setState({[countryAss+'-'+val]:event.target.value});
		}else{
			this.setState({[countryAss+'-'+val]:''});
		}
	}
	
	assignedCountry=()=>{
		let countryAss = this.state.countryAss;
		
		if(!countryAss){
			alert('Please select the Country!');
			return false;
		}
		
		let payerTypes = this.state.payerTypes;
		let typeIds = [];
		if(payerTypes){
			payerTypes.map((val, i) => {
				//console.log('payerTypes->',this.state[countryAss+'-'+val.id]);
				if(this.state[countryAss+'-'+val.id]){
					typeIds.push(val.id);
				}
			});
		}
		
		let ptypeIds = typeIds.join(',');
		
		if(ptypeIds ==''){
			alert('Please select the payer type to assigned!');
			return false;
		}
		
		let ApiUrl = $('#ApiUrl').val();
		let url = ApiUrl+'assign-payer-type';
		let formData = new FormData();
		formData.append('country_id', countryAss);
		formData.append('typeIds', ptypeIds);
		axios({
			method: 'POST',
			url: url,
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
		.then(response => {
			this.getPayerTypes();
			alert('The data updated successfully!');
		}).catch(error => {
			alert('error::'+ error);
		})
	}
	
	handleSort = column => {
		const { sortBy, sortOrder } = this.state;
		const isAsc = sortBy === column && sortOrder === 'asc';
		this.setState({
		  sortBy: column,
		  sortOrder: isAsc ? 'desc' : 'asc'
		});
	};
	
	handleFileUpload = (event) => {
		const file = event.target.files[0]; // Get the uploaded file
		if (file) {
		  const reader = new FileReader();
		  
		  reader.onload = (e) => {
			const binaryString = e.target.result;
			const workbook = XLSX.read(binaryString, { type: 'binary' });
			const sheetName = workbook.SheetNames[0];
			const worksheet = workbook.Sheets[sheetName];
			const rows = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
			this.setState({ xlsxdata: rows });
		  };
		  reader.readAsBinaryString(file);
		}
	};
	
	uploadCompleteData=()=>{
		let xlsxdata = this.state.xlsxdata;
		let user_id = localStorage.getItem('user_id');
		let  parms = [];
		xlsxdata.map((val, i) => {
			if(i > 9){
				parms.push(val);
			}
		});
		let jsonData = {
			"guide_id": xlsxdata[8][1],
			"user_id": user_id,
			"paramters": parms,
		}
		if(jsonData){
			this.saveCompleteData(jsonData);
		}
	}
	
	saveCompleteData=(jsonData)=>{
		let ApiUrl = $('#ApiUrl').val();
		let url = ApiUrl+'save-guide-data';
		axios({
			method: 'POST',
			url:url,
			data:JSON.stringify(jsonData),
			headers: {
				'Content-Type': 'application/json',
			}
		})
		.then(response => {
			let res = response.data;
			$('#fileInput').val('');
			$('#fileUpload').modal('hide');
			this.getFeeGuides();
			alert(res);
		}).catch(error => {
			console.log('error::', error);
		})
	}
	
	render() {

		const { country,region,payers,payerTypes,addEditType,cusTypeName,payerLocation,states,payerStatesArray,parameters,showTempArea,showTempGen,showLibrary,templateHeaderParms,templateColumnParms,tempHeaders,tempColumns,templates,feeGuides,feeGuidesFilter,effectiveDate,eeffectiveDate,efeeId,assignpayerTypes,sortBy,sortOrder} = this.state;
		
		console.log('feeGuides->',feeGuides);
		
		let that=this;
		let href = window.location.href.split('?')[0];
		let role = localStorage.getItem('role');
		let payersHtml = payers.map(function(val,i) {
			return (
				<option value={val.id} key={i}>{val.name}</option>
			);
		})
		
		let parametersHtml = parameters.map(function(val,i) {
			if(val.id != 1 && val.id != 2 && val.id != 3){
				return (
					<option value={val.id} key={i}>{val.name}</option>
				);
			}
		})
		
		let parametersList = parameters.map(function(value,i) {
			let className = 'light-yellow';
			if(value.id == 1 || value.id == 2 || value.id == 3){
				className = 'light-green';
			}
			return (
				<div key={i} index={i} id={value.id} className={'wt-section field-div field-div-2 ' + className +' move-'+value.id}><span className="showcase"></span><span className="input-title task-input-4">{value.name}</span></div>
			);
		})
		
		let templateHeaderParmsList = templateHeaderParms.map(function(value,i) {
			let className = 'light-yellow';
			if(value.id == 1 || value.id == 2 || value.id == 3){
				className = 'light-green';
			}
			
			return (<div id={value.id} className={'wt-section field-div ' + className +' move-'+value.id}><span className="showcase"></span><span className="input-title task-input-4">{value.name}</span></div>);
		})
		
		let templateColumnParmsList = templateColumnParms.map(function(value,i) {
			let className = 'light-yellow';
			if(value.id == 1 || value.id == 2 || value.id == 3){
				className = 'light-green';
			}
			
			return (<div id={value.id} className={'wt-section field-div ' + className +' move-'+value.id}><span className="showcase"></span><span className="input-title task-input-4">{value.name}</span></div>);
		})
		

		let payerTypesHtml = payerTypes.map(function(val,i) {
			return (
				<option value={val.id} key={i}>{val.type}</option>
			);
		})
		
		let assignPayerTypesHtml = assignpayerTypes.map(function(val,i) {
			return (
				<option value={val.id} key={i}>{val.type}</option>
			);
		})
		
		let statesHtml = states.map(function(val,i) {
			return (
				<option value={val.id} key={i}>{val.name}</option>
			);
		})
		
		let payerLocationHtml = payerLocation.map(function(val,i) {
			return (
				<option value={val.id} key={i}>{val.name}</option>
			);
		})
		
		let templateHtml = templates.map(function(val,i) {
			return (
				<option value={val.id} key={i}>{val.name}</option>
			);
		})
		
		let payerTypeAsssHtml = payerTypes.map(function(val,i) {
			let countryAss = that.state.countryAss;
			return (
				<div><input type="checkbox" name={'ptype-'+val.id} value={val.id} checked={that.state[countryAss+'-'+val.id] ? true : false} onChange={that.setAssignedTypes}/> {val.type}</div>
			);
			
		})
		
		/* const DragHandle = sortableHandle(() => <span className="showcase"></span>);
		const SortableItem = sortableElement(({value}) => {
			let className = 'light-yellow';
			if(value.id == 1 || value.id == 2 || value.id == 3){
				className = 'light-green';
			}
			
			return (<div id={value.id} className={'wt-section field-div field-div-2 ' + className +' move-'+value.id}><DragHandle /><span className="input-title task-input-4">{value.name}</span></div>);
			
		}); */
		
		/* const SortableItem2 = sortableElement(({value}) => {
			let className = 'light-yellow';
			if(value.id == 1 || value.id == 2 || value.id == 3){
				className = 'light-green';
			}
			
			return (<div id={value.id} className={'wt-section field-div ' + className +' move-'+value.id}><DragHandle /><span className="input-title task-input-4">{value.name}</span></div>);
			
		}); */
		
		/* const SortableContainer = sortableContainer(({children}) => {
		  return <div className="status-inner">{children}</div>;
		}); */
		
		const sortedData = [...feeGuides].sort((a, b) => {
		  const isAsc = sortOrder === 'asc';
		  if (a[sortBy] < b[sortBy]) {
			return isAsc ? -1 : 1;
		  }
		  if (a[sortBy] > b[sortBy]) {
			return isAsc ? 1 : -1;
		  }
		  return 0;
		});
		
		const StyledTableCell = styled(TableCell)(({ theme }) => ({
		  [`&.${tableCellClasses.head}`]: {
			backgroundColor: '#e9e9e9',
			color: '#000;',
			fontWeight: 600,
			fontSize: 15,
		  },
		  [`&.${tableCellClasses.body}`]: {
			fontSize: 14,
		  },
		}));
		
		//console.log('feeGuides->',feeGuides);

		return (
			<div>
				<aside className="sidebar">
				  <div className="toggle toggle-btn">
					<a href="#" className="burger js-menu-toggle active" data-toggle="collapse" data-target="#main-navbar">
						<span></span>
					</a>
				  </div>
				  <div className="side-inner align-items-end" >
					<div className="sidebar-content">
						<div className="nav-content">
							<h5 className="page-title">Payer Management</h5>
							<div className="left-link-box-new mb-5">
								<button className="btn btn-header-link" data-toggle="modal" data-target="#payerLocation" data-dismiss="modal">Payer Location Manager</button>
								<button className="btn btn-header-link" data-toggle="modal" data-target="#payerType" data-dismiss="modal">Payer Type Manager</button>
								<button className="btn btn-header-link" data-toggle="modal" data-target="#payerTypeAss" data-dismiss="modal">Payer Type Assignment</button>
								<button className="btn btn-header-link" data-toggle="modal" data-target="#payer" data-dismiss="modal">Create/Edit Payer</button>
							</div>
							
							<h5 className="page-title">Guide Management</h5>
							<div className="left-link-box-new">
								<button className="btn btn-header-link" data-toggle="modal" data-target="#feeParameters" data-dismiss="modal">Fee Guide Parameters</button>
								<button className="btn btn-header-link" onClick={()=>this.templateManager()}>Fee Guide Template Manager</button>
								<button className="btn btn-header-link" onClick={()=>this.templateGenerator()}>Fee Guide Template Generator</button>
								<button className="btn btn-header-link" onClick={()=>this.showGuideLibrary()}>Fee Guide Library</button>
							</div>
						</div>
					</div>
				  </div>
				</aside>
				
				<div id="page-content-wrapper" className="workflow-manager">
					<div className="temp-list">
						<div className="row">
							{showTempArea ?
							<div className="col-md-12 mb-4">
								<h4 className="mb-4">Fee Guide Template Manager</h4>
								<div className="row justify-content-between main-parm-dev">
									<div className="col-md-5">
										<div className="form-group">
											<select className="form-control" name="selectedTemplate" value={this.state.selectedTemplate} onChange={this.getValue}>
												<option value={''}>Select Fee Guide Template</option>
												{templateHtml}
											</select>
										</div>
										<h6> Fee Guide Parameters  </h6>
										
										<div className="parms-div">
											{parameters.map((value,i) =>{
												let className = 'light-yellow';
												if(value.id == 1 || value.id == 2 || value.id == 3){
													className = 'light-green';
												}
													return (<div id={value.id} className={'wt-section field-div ' + className +' move-'+value.id}><span className="input-title task-input-4">{value.name}</span><div className="parms-left-checkbox"><input type="checkbox" name={value.id} id={value.id} checked={tempHeaders.get(value.id) ? true:false} onChange={this.setTempHeaders}/><input type="checkbox" name={value.id} id={value.id} checked={tempColumns.get(value.id) ? true:false} onChange={this.setTempColumns}/></div></div>);
												})}
										</div>
									</div>
									<div className="col-md-6">
										<div className="row">
											<div className="col-md-12 mb-2">
												<h6> Fee Guide Template Header Data </h6>
												<div className="parms-div-right border">
													{/* templateHeaderParms ?
														<SortableContainer onSortEnd={this.onSortEndHeader} useDragHandle>
															{templateHeaderParms.map((value, index) => (
															  <SortableItem2 key={index} index={index} value={value} /> 
															))}
														</SortableContainer>
													:null */}
													{templateHeaderParms ?
														<ReactSortable
															list={this.state.templateHeaderParms}
															setList={(newState) => this.setState({ templateHeaderParms: newState })}
															onEnd={this.onSortEndHeader}
														>{templateHeaderParmsList}</ReactSortable>
													:null}
												</div>
											</div>
											<div className="col-md-12 mb-2">
												<h6> Fee Guide Template Column Data  </h6>
												<div className="parms-div-right border">
													{/* templateColumnParms ?
														<SortableContainer onSortEnd={this.onSortEndColumn} useDragHandle>
															{templateColumnParms.map((value, index) => (
															  <SortableItem2 key={index} index={index} value={value} /> 
															))}
														</SortableContainer>
													:null */}
													{templateColumnParms ?
														<ReactSortable
															list={this.state.templateColumnParms}
															setList={(newState) => this.setState({ templateColumnParms: newState })}
															onEnd={this.onSortEndColumn}
														>{templateColumnParmsList}</ReactSortable>
													:null}
												</div>
											</div>
										</div>
									</div>
									<div className="col-md-12 mt-4">
										<div className="row justify-content-center">
											<div className="col-md-4 pr-0">
												<input className="form-control" name="templateName" value={this.state.templateName} type="text" onChange={this.getValue} placeholder="Template Name"/>
											</div>
											<div className="col-auto">
												<button onClick={()=>this.saveGuideTemplate()} type="button" className="mt-0 btn btn-info float-right mr-1" >Save Fee Guide Template</button>
											</div>
										</div>
									</div>
								</div>
							</div>
							:null}
							
							{showTempGen ? 
								<div className="col-md-12 mb-4">
									<h4 className="mb-4">Fee Guide Template Generator</h4>
									
									<div className="row justify-content-between main-parm-dev">
									<div className="col-md-5">
										<div className="form-group">
											<h5 className="mb-4">Create a blank template for a new fee guide</h5>
											<select className="form-control" name="sTemplate" value={this.state.sTemplate} onChange={this.getValue}>
												<option value={''}>Select Fee Guide Template</option>
												{templateHtml}
											</select>
										</div>
										<div className="form-group">
											<h6 className="mb-1">Select Fee Guide Year</h6>
											<select className="form-control" name="guideYear" value={this.state.guideYear} onChange={this.getValue}>
												<option value=''>Select a Year</option>
												<option value='2023'>2023</option>
												<option value='2024'>2024</option>
												<option value='2025'>2025</option>
												<option value='2026'>2026</option>
												
											</select>
										</div>
										
										<div className="form-group">
											<h6 className="mb-1">Fee Guide Effective Date</h6>
											{/* <DatePicker className="date-picker form-control " placeholderText="mm/dd/yyyy" selected={this.state.effectiveDate} onChange={this.handleChangeStart}/> */}
											
											<LocalizationProvider dateAdapter={AdapterDayjs}>
											 <DatePicker
												inputFormat="MM/dd/yyyy"		
												value={this.state.effectiveDate}
												onChange={this.handleChangeStart}
											  />
											</LocalizationProvider>
										</div>
										
										<div className="form-group">
											<h6 className="mb-1">Payer List</h6>
											<select className="form-control" name="sPayer" value={this.state.sPayer} onChange={this.getPayerDetails}>
												<option value={''}>Select Payer</option>
												{payersHtml}
											</select>
										</div>
										<div className="form-group">
											<label>Name of Fee Guide:</label>
											<input className="form-control" name="namefeeGuide" value={this.state.namefeeGuide} type="text" onChange={this.getValue}/>
										</div>
										<div className="col-group">
											<button onClick={()=>this.downloadGuideTemplate()} type="button" className="mt-0 btn btn-info float-right mr-1" >Download Blank Fee Guide Template</button>
										</div>
									</div>
									</div>
									
								</div>
							:null}
							
							
							{showLibrary ?
							
								<div className="col-md-12 mb-4">
									<h4 className="mb-4">Fee Guide Library</h4>
									
									<div className="guides-filters">
							
										<div className="wp-filter">
											<FormControl sx={{ m: 1, minWidth: 140, maxWidth: 158 }} size="small">
												<InputLabel>Country</InputLabel>
												<Select
													value={this.state.filterCountry ? this.state.filterCountry : ""}
													name={'filterCountry'}
													onChange={this.getValue}
													input={<OutlinedInput label="Category" />}
												>
												<MenuItem value={''} >None</MenuItem>
												{payerLocation.map(function(val,i) {
													return (
														<MenuItem value={val.id} key={i}>{val.name}</MenuItem>
													);
												})}
												</Select>
											</FormControl>
										</div>
										
										<div className="wp-filter">
											<FormControl sx={{ m: 1, minWidth: 160, maxWidth: 158 }} size="small">
												<InputLabel>States / Provinces</InputLabel>
												<Select
													value={this.state.filterState ? this.state.filterState : ""}
													name={'filterState'}
													onChange={this.getValue}
													input={<OutlinedInput label="States / Provinces" />}
												>
												<MenuItem value={''} >None</MenuItem>
												{states.map(function(val,i) {
													return (
														<MenuItem value={val.id} key={i}>{val.name}</MenuItem>
													);
												})}
												</Select>
											</FormControl>
										</div>
										
										<div className="wp-filter">
											<FormControl sx={{ m: 1, minWidth: 140, maxWidth: 158 }} size="small">
												<InputLabel>Payer Name</InputLabel>
												<Select
													value={this.state.filterPayer ? this.state.filterPayer : ""}
													name={'filterPayer'}
													onChange={this.getValue}
													input={<OutlinedInput label="Payer Name" />}
												>
												<MenuItem value={''} >None</MenuItem>
												{payers.map(function(val,i) {
													return (
														<MenuItem value={val.id} key={i}>{val.name}</MenuItem>
													);
												})}
												</Select>
											</FormControl>
										</div>
										
										<div className="wp-filter">
											<FormControl sx={{ m: 1, minWidth: 140, maxWidth: 158 }} size="small">
												<InputLabel>Payer Type</InputLabel>
												<Select
													value={this.state.filterPayerType ? this.state.filterPayerType : ""}
													name={'filterPayerType'}
													onChange={this.getValue}
													input={<OutlinedInput label="Payer Type" />}
												>
												<MenuItem value={''} >None</MenuItem>
												{payerTypes.map(function(val,i) {
													return (
														<MenuItem value={val.id} key={i}>{val.type}</MenuItem>
													);
												})}
												</Select>
											</FormControl>
										</div>
										
										<div className="wp-filter">
											<FormControl sx={{ m: 1, minWidth: 100, maxWidth: 158 }} size="small">
												<InputLabel>Year</InputLabel>
												<Select
													value={this.state.filterYear ? this.state.filterYear : ""}
													name={'filterYear'}
													onChange={this.getValue}
													input={<OutlinedInput label="Year" />}
												>
												<MenuItem value={''} >None</MenuItem>
												<MenuItem value={2023} >2023</MenuItem>
												<MenuItem value={2024} >2024</MenuItem>
												<MenuItem value={2025} >2025</MenuItem>
												<MenuItem value={2026} >2026</MenuItem>
												
												</Select>
											</FormControl>
										</div>
										
										<div className="wp-filter">
											<FormControl sx={{ m: 1, minWidth: 100, maxWidth: 158 }} size="small">
												<InputLabel>Active</InputLabel>
												<Select
													value={this.state.filterStatus ? this.state.filterStatus : ""}
													name={'filterStatus'}
													onChange={this.getValue}
													input={<OutlinedInput label="Active" />}
												>
												<MenuItem value={'N'} >No</MenuItem>
												<MenuItem value={'Y'} >Yes</MenuItem>
												</Select>
											</FormControl>
										</div>
										
										<div className="wp-filter">
											<FormControl sx={{ m: 1, minWidth: 100, maxWidth: 158 }} size="small">
												<InputLabel>Uploaded (Y/N)</InputLabel>
												<Select
													value={this.state.filterUploaded ? this.state.filterUploaded : ""}
													name={'filterUploaded'}
													onChange={this.getValue}
													input={<OutlinedInput label="Uploaded (Y/N)" />}
												>
												<MenuItem value={'N'} >No</MenuItem>
												<MenuItem value={'Y'} >Yes</MenuItem>
												</Select>
											</FormControl>
										</div>
										
										<div className="feedatefilter">
										{/* <DatePicker className="date-picker form-control" placeholderText="Effective Date Start" selected={this.state.startDate} onChange={this.handleChangeStartv2}/> <div> - </div>
										<DatePicker className="date-picker form-control" placeholderText="Effective Date End" selected={this.state.endDate} onChange={this.handleChangeEnd}/> */}
										
										<LocalizationProvider dateAdapter={AdapterDayjs}>
										 <DatePicker className="date-picker form-control"
											inputFormat="MM/dd/yyyy"		
											value={this.state.startDate}
											onChange={this.handleChangeStartv2}
											renderInput={(params) => <TextField {...params} size="small" />}
										  />
										</LocalizationProvider>
										<div> - </div>
										<LocalizationProvider dateAdapter={AdapterDayjs}>
										 <DatePicker className="date-picker form-control"
											inputFormat="MM/dd/yyyy"		
											value={this.state.endDate}
											onChange={this.handleChangeEnd}
											renderInput={(params) => <TextField {...params} size="small" />}
										  />
										</LocalizationProvider>
										
										</div>
										<div className="upload-btn">
											<button data-toggle="modal" data-target="#fileUpload" data-dismiss="modal" type="button" className="btn btn-info float-right mr-1" >Uploaded Fee Guide Template</button>
										</div>
									</div>
									
									<div className="row justify-content-between main-parm-dev">
									<Paper sx={{ width: '100%', overflow: 'hidden' }}>
									<TableContainer>
									  <Table size="small" aria-label="a dense table">
										<TableHead>
										  <TableRow>
											<StyledTableCell>
											<TableSortLabel
												active={sortBy === 'fee_guide'}
												direction={sortOrder}
												onClick={() => this.handleSort('fee_guide')}
											>
											Fee Guide ID
											</TableSortLabel>
											</StyledTableCell>
											
											<StyledTableCell>
											<TableSortLabel
												active={sortBy === 'fee_guide_name'}
												direction={sortOrder}
												onClick={() => this.handleSort('fee_guide_name')}
											>
											Fee Guide Name
											</TableSortLabel>
											</StyledTableCell>
											
											<StyledTableCell>
											<TableSortLabel
												active={sortBy === 'payer_name'}
												direction={sortOrder}
												onClick={() => this.handleSort('payer_name')}
											>
											Payer Name
											</TableSortLabel>
											</StyledTableCell>
											
											<StyledTableCell>
											<TableSortLabel
												active={sortBy === 'payer_type'}
												direction={sortOrder}
												onClick={() => this.handleSort('payer_type')}
											>
											Payer Type
											</TableSortLabel>
											</StyledTableCell>
											
											<StyledTableCell>
											<TableSortLabel
												active={sortBy === 'fee_guide_year'}
												direction={sortOrder}
												onClick={() => this.handleSort('fee_guide_year')}
											>
											Fee Guide Year
											</TableSortLabel>
											</StyledTableCell>
											
											<StyledTableCell>
											<TableSortLabel
												active={sortBy === 'fee_guide_date'}
												direction={sortOrder}
												onClick={() => this.handleSort('fee_guide_date')}
											>
											Effective Date
											</TableSortLabel>
											</StyledTableCell>
											
											<StyledTableCell>Ver.</StyledTableCell>
											
											<StyledTableCell>Last Date Edited</StyledTableCell>
											
											<StyledTableCell>
											<TableSortLabel
												active={sortBy === 'country_name'}
												direction={sortOrder}
												onClick={() => this.handleSort('country_name')}
											>
											Country
											</TableSortLabel>
											</StyledTableCell>
											<StyledTableCell>States / Provinces</StyledTableCell>
											<StyledTableCell>File Uploaded</StyledTableCell>
											<StyledTableCell>Upload Time</StyledTableCell>
											<StyledTableCell>Action</StyledTableCell>
											
										  </TableRow>
										</TableHead>
										<TableBody>
										  {sortedData.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((row) => {
											
											let date = new Date().toLocaleString("en-US", {timeZone: "America/New_York"});
											let upIcon = 'red-1.png';
											if(row.details){
												upIcon = 'green.png';
											}
											
											let version = '1.0';
											let uploadedTime = '';
											
											if(row.details){
												version = row.details.version;
												uploadedTime = moment(row.details.created_at).format('MM-DD-YYYY h:mm:ss A');
											}
											
											return (<TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
											  <TableCell>{row.fee_guide}</TableCell>
											  <TableCell>{row.fee_guide_name}</TableCell>
											  <TableCell>{row.payer.name}</TableCell>
											  <TableCell>{row.payer.payer_type}</TableCell>
											  <TableCell>{row.fee_guide_year}</TableCell>
											  <TableCell>{moment(row.fee_guide_date).format('MM-DD-YYYY')}</TableCell>
											  <TableCell>{version}</TableCell>
											  <TableCell>{moment(row.fee_guide_date).format('MM-DD-YYYY')}</TableCell>
											  <TableCell>{row.payer.country_name}</TableCell>
											  <TableCell>{row.payer.states_name}</TableCell>
											  <TableCell><img src={href+'/'+upIcon} alt="View" width="15" height="15" /></TableCell>
											  <TableCell>{uploadedTime}</TableCell>
											  
											  <TableCell>
											  <div className="d-flex">
											  <input className="btn copy-code-btn check-input" type="checkbox" name={row.id+'-active'} checked={this.state[row.id+'-active'] == 'Y' || row.status == 'Y' ? 'checked' : ''} onChange={()=>this.changeStatus(row.id,row.id+'-active')} />
											  
											  <button onClick={()=>this.editFeeGuide(row)} type="button" className="btn copy-code-btn"><i className="fa fa-edit" aria-hidden="true"></i></button>
											  {row.details ?
											   <button className="btn copy-code-btn" type="button" onClick={()=>this.copyTemplate(row)}><i className="fa fa-copy" aria-hidden="true"></i></button>
											   :
												<button className="btn copy-code-btn" type="button" disabled><i className="fa fa-copy" aria-hidden="true"></i></button>
												} 
											  <button className="btn copy-code-btn" type="button" onClick={()=>this.downloadBlankTemplate(row)}><i className="fa fa-download" aria-hidden="true"></i></button>
												{row.details ? 
												<button className="btn copy-code-btn icon-complated" type="button" onClick={()=>this.downloadComplatedTemplate(row)}><i className="fa fa-download" aria-hidden="true"></i></button>
												:
												<button className="btn copy-code-btn" type="button" disabled><i className="fa fa-download" aria-hidden="true"></i></button>
												}  
											  <button onClick={() => { if (window.confirm('Are you sure you want to delete this Fee Guide?')) this.deleteFeeGuide(row.id) } } type="button" className="btn"><i className="fa fa-trash"></i></button>
											  </div>
											  </TableCell>
											  
											</TableRow>)
										  })}
										</TableBody>
									  </Table>
									</TableContainer>
									<TablePagination
										rowsPerPageOptions={[5, 10, 20, 50, 100]}
										component="div"
										count={sortedData.length}
										rowsPerPage={this.state.rowsPerPage}
										page={this.state.page}
										onPageChange={this.handleChangePage}
										onRowsPerPageChange={this.handleChangeRowsPerPage}
									  />
								</Paper>
									</div>
									
								</div>
								
							:null}
							
						</div>
					</div>
				</div>
				
				<div className="modal" id={'editFeeGuide'}>
					<div className="modal-dialog modal-lg modal-sm select-modal">
						<div className="modal-content">
							<div className="modal-header">
								<h4 className="modal-title">Edit Fee Guide</h4>
								<button type="button" className="close" data-dismiss="modal">&times;</button>
							</div>
							<div className="modal-body">
								<div className="col-md-12">
									<div className="form-group">
										<label>Name of Fee Guide:</label>
										<input className="form-control" name="enamefeeGuide" value={this.state.enamefeeGuide} type="text" onChange={this.getValue}/>
									</div>
									
									<div className="form-group">
										<select className="form-control" name="esTemplate" value={this.state.esTemplate} onChange={this.getValue}>
											<option value={''}>Select Fee Guide Template</option>
											{templateHtml}
										</select>
									</div>
									<div className="form-group">
										<h6 className="mb-1">Select Fee Guide Year</h6>
										<select className="form-control" name="eguideYear" value={this.state.eguideYear} onChange={this.getValue}>
											<option value=''>Select a Year</option>
											<option value='2023'>2023</option>
											<option value='2024'>2024</option>
											<option value='2025'>2025</option>
											<option value='2026'>2026</option>
											
										</select>
									</div>
									{ this.state.eeffectiveDate ?
									<div className="form-group">
										<h6 className="mb-1">Fee Guide Effective Date</h6>
										{/* <DatePicker className="date-picker form-control " placeholderText="mm/dd/yyyy" selected={this.state.eeffectiveDate} onChange={this.handleChangeStartv3}/> */}
										<LocalizationProvider dateAdapter={AdapterDayjs}>
										 <DatePicker
											inputFormat="MM/dd/yyyy"		
											value={this.state.eeffectiveDate}
											onChange={this.handleChangeStartv3}
										  />
										</LocalizationProvider>
									</div>
									:null }
									
									<div className="form-group">
										<h6 className="mb-1">Payer List</h6>
										<select className="form-control" name="esPayer" value={this.state.esPayer} onChange={this.getPayerDetails}>
											<option value={''}>Select Payer</option>
											{payersHtml}
										</select>
									</div>
									
									<div className="form-group">
										<h6 className="mb-1">Active</h6>
										<select className="form-control" name="eStatus" value={this.state.eStatus} onChange={this.getValue}>
											<option value={'N'}>No</option>
											<option value={'Y'}>Yes</option>
										</select>
									</div>
									
								</div>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-primary float-left" onClick={()=>this.updateFeeGuide()}>Update</button>
								<button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
							</div>
						</div>
					</div>
				</div>
				
				<div className="modal" id={"feeParameters"} role="dialog">
					<div className="modal-dialog modal-lg">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Fee Guide Additional Parameters</h5>
								<button type="button" className="close" data-dismiss="modal">&times;</button>
							</div>
							<div className="modal-body">
								<div>
									<label>Parameters:</label>
									<select className="form-control mb-2" name="parms" value={this.state.parms} onChange={this.getValue}>
										<option value={''}>Select Parameter</option>
										{parametersHtml}
									</select>
									
									<span className="w-edit-span" >
									<button onClick={()=>this.addParms()} type="button" className="btn"><i className="fa fa-plus"></i></button>
									
									{this.state.parms ?
									<button onClick={()=>this.editParms()} type="button" className="btn"><img src={href+'/Icon-Edit.png'} alt="edit" width="18" height="18"/></button>
									:null}
									{this.state.parms ?
									<button onClick={() => { if (window.confirm('Are you sure you want to delete this parameter?')) this.deleteParms(this.state.parms) } } type="button" className="btn"><i className="fa fa-trash"></i></button>
									:null}
									</span>
									
									{ this.state.addEditParms ?
									<div className="new-category-input mb-2">
										<label>Add&Edit Fee Guide Parameter:</label>
										<input className="form-control" name="cusParmsName" value={this.state.cusParmsName} type="text" onChange={this.getValue}/>
									</div>
									:null}
									
								</div>
								
								<div className="parms-div">
									{/* parameters ?
										<SortableContainer onSortEnd={this.onSortEnd} useDragHandle>
											{parameters.map((value, index) => (
											  <SortableItem key={index} index={index} value={value} /> 
											))}
										</SortableContainer>
									:null */}
									
									<ReactSortable
										list={this.state.parameters}
										setList={(newState) => this.setState({ parameters: newState })}
										//onEnd={this.handleSortEnd}
									>{parametersList}</ReactSortable>
									
								</div>
								
								
							</div>
							<div className="modal-footer">
								<div className="popup-btn-com">
									<button type="button" className="btn btn-danger float-right" data-dismiss="modal">Close</button>
									<button onClick={()=>this.addUpdateParms()} type="button" className="btn btn-info float-right mr-1" >Update</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				
				<div className="modal" id={"payer"} role="dialog">
					<div className="modal-dialog modal-lg">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Create / Edit Payer</h5>
								<button type="button" className="close" data-dismiss="modal">&times;</button>
							</div>
							<div className="modal-body">
								<div className="mb-2">
									<label>Payer</label>
									<select className="form-control" name="payer" value={this.state.payer} onChange={this.getValue}>
										<option value={''}>Select Payer</option>
										{payersHtml}
									</select>
								</div>
								<div className="mb-2">
									<label>Country</label>
									<select className="form-control" name="payerCountry" value={this.state.payerCountry} onChange={this.selectCountry}>
										<option value={''}>Select Country</option>
										{payerLocationHtml}
									</select>
								</div>
								{this.state.payerCountry ?
								<div className="mb-2">
									<label>Select State, Provinces or Regions</label>
									<select className="form-control mb-2" name="payerStates" value={this.state.payerStatesArray} onChange={this.selectRegion} multiple>
										<option value={''}>Select State, Provinces or Regions</option>
											{statesHtml}
									</select>
								</div>
								:null}
								<div className="mb-2">
									<label>Payer Type</label>
									<select className="form-control" name="selectpayerType" value={this.state.selectpayerType} onChange={this.getValue}>
										<option value={''}>Select Payer Type</option>
										{assignPayerTypesHtml}
									</select>
								</div>
								
								<div className="mb-2">
									<label>Save or Update Payer Name:</label>
									<input className="form-control" name="cusPayerName" value={this.state.cusPayerName} type="text" onChange={this.getValue}/>
								</div>
								
							</div>
							<div className="modal-footer">
								<div className="popup-btn-com">
									<button type="button" className="btn btn-danger float-right" data-dismiss="modal">Close</button>
									<button onClick={()=>this.addUpdatePayer()} type="button" className="btn btn-info float-right mr-1" >Save / Update</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				
				<div className="modal" id={"payerLocation"} role="dialog">
					<div className="modal-dialog modal-lg">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Payer Location Manager</h5>
								<button type="button" className="close" data-dismiss="modal">&times;</button>
							</div>
							<div className="modal-body">
							
								<div>
									<div>
									<select className="form-control mb-2" name="payerLoc" value={this.state.payerLoc} onChange={this.selectCountry}>
										<option value={''}>Select Country</option>
										{payerLocationHtml}
									</select>
									<span className="w-edit-span" >
									<button onClick={()=>this.addPayerLoc()} type="button" className="btn"><i className="fa fa-plus"></i></button>
									
									{this.state.payerLoc ?
									<button onClick={()=>this.editPayerLoc()} type="button" className="btn"><img src={href+'/Icon-Edit.png'} alt="edit" width="18" height="18"/></button>
									:null}
									{this.state.payerLoc ?
									<button onClick={() => { if (window.confirm('Are you sure you want to delete this Country?')) this.deleteCountry(this.state.payerLoc) } } type="button" className="btn"><i className="fa fa-trash"></i></button>
									:null}
									</span>
									</div>
									{ this.state.addEditLoc ?
									<div className="new-category-input mb-2">
										<label>Add&Edit Country:</label>
										<input className="form-control" name="cusCntryName" value={this.state.cusCntryName} type="text" onChange={this.getValue}/>
									</div>
									:null}
									{this.state.payerLoc ?
										<div>
										<select className="form-control mb-2" name="locState" value={this.state.locState} onChange={this.selectRegion}>
											<option value={''}>Select State, Provinces or Regions</option>
											{statesHtml}
										</select>
										<span className="w-edit-span" >
										<button onClick={()=>this.addPayerLocState()} type="button" className="btn"><i className="fa fa-plus"></i></button>
										{this.state.locState ?
										<button onClick={()=>this.editPayerLocState()} type="button" className="btn"><img src={href+'/Icon-Edit.png'} alt="edit" width="18" height="18"/></button>
										:null}
										{this.state.locState ?
										<button onClick={() => { if (window.confirm('Are you sure you want to delete this State, Provinces or Regions?')) this.deleteState(this.state.locState) } } type="button" className="btn"><i className="fa fa-trash"></i></button>
										:null}
										</span>
										
										{this.state.addEditState ?
										<div className="new-category-input">
											<label>Add&Edit Select State, Provinces or Regions:</label>
											<input className="form-control" name="cusStateName" value={this.state.cusStateName} type="text" onChange={this.getValue}/>
										</div>
										:null}
										
										</div>
										
									:null}
								</div>
							</div>
							<div className="modal-footer">
								<div className="popup-btn-com">
									<button type="button" className="btn btn-danger float-right" data-dismiss="modal">Close</button>
									<button onClick={()=>this.addUpdateLocation()} type="button" className="btn btn-info float-right mr-1" >Add&Update</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				
				<div className="modal" id={"payerTypeAss"} role="dialog">
					<div className="modal-dialog modal-lg">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Payer Type Assignment</h5>
								<button type="button" className="close" data-dismiss="modal">&times;</button>
							</div>
							<div className="modal-body">
								<div>
									<label>Select Country:</label>
									<select className="form-control mb-2" name="countryAss" value={this.state.countryAss} onChange={this.getValue}>
										<option value={''}>Select Country</option>
										{payerLocationHtml}
									</select>
								</div>
								<div>
									<label>Select all payer types available in this country:</label>
									{payerTypeAsssHtml}
								</div>
								
							</div>
							<div className="modal-footer">
								<div className="popup-btn-com">
									<button type="button" className="btn btn-danger float-right" data-dismiss="modal">Close</button>
									<button onClick={()=>this.assignedCountry()} type="button" className="btn btn-info float-right mr-1" >Update</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				
				<div className="modal" id={"payerType"} role="dialog">
					<div className="modal-dialog modal-lg">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Payer Type Manager</h5>
								<button type="button" className="close" data-dismiss="modal">&times;</button>
							</div>
							<div className="modal-body">
								<label>Payer Types:</label>
								<div>
								<select className="form-control w-edit-category" name="wEditType" onChange={this.getValue}>
									<option value={''}>Select Payer Type</option>
									{payerTypesHtml}
								</select>
								<span className="w-edit-span" >
								<button onClick={()=>this.addPayerType()} type="button" className="btn"><i className="fa fa-plus"></i></button>
								
								{this.state.wEditType ?
								<button onClick={()=>this.editPayerType()} type="button" className="btn"><img src={href+'/Icon-Edit.png'} alt="edit" width="18" height="18"/></button>
								:null}
								{this.state.wEditType ?
								<button onClick={() => { if (window.confirm('Are you sure you want to delete this type?')) this.deleteType(this.state.wEditType) } } type="button" className="btn"><i className="fa fa-trash"></i></button>
								:null}
								</span>
								</div>
								{ addEditType ?
								<div className="new-category-input">
									<label>Add&Edit Payer Type name:</label>
									<input className="form-control" name="cusTypeName" value={cusTypeName} type="text" onChange={this.getValue}/>
								</div>
								:null}
								
							</div>
							<div className="modal-footer">
								<div className="popup-btn-com">
									<button type="button" className="btn btn-danger float-right" data-dismiss="modal">Close</button>
									<button onClick={()=>this.addUpdateType()} type="button" className="btn btn-info float-right mr-1" >Add&Update</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				
				<div className="modal" id={"fileUpload"} role="dialog">
					<div className="modal-dialog modal-lg">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Upload the completed Fee Guide XLSX file</h5>
								<button type="button" className="close" data-dismiss="modal">&times;</button>
							</div>
							<div className="modal-body">
									<label>Upload XLSX File</label>
									<input id='fileInput' type="file" accept=".xlsx, .xls" onChange={this.handleFileUpload} />
							</div>
							<div className="modal-footer">
								<div className="popup-btn-com">
									<button type="button" className="btn btn-danger float-right" data-dismiss="modal">Close</button>
									<button type="button" className="btn btn-info float-right mr-1" onClick={()=>this.uploadCompleteData()}>upload</button>
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>
		);	
	}
}
