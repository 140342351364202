import React, { Component } from 'react';
import $ from 'jquery';
import axios,{post,get} from 'axios';
import moment from 'moment';
import { ulid } from 'ulid'
import arrayMove from "./arrayMove";
import { CSVLink } from "react-csv";
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import { copyImageToClipboard } from 'copy-image-clipboard';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {stateFromHTML} from 'draft-js-import-html';
import {convertToRaw, EditorState, convertFromHTML, convertFromRaw,ContentState} from 'draft-js';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Button, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { MenuItem } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import OutlinedInput from '@mui/material/OutlinedInput';
import parse from 'html-react-parser';
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable';
import html2canvas from "html2canvas";

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';


export default class WorkFlowDetails extends Component {
	
	constructor(props){
        super(props)
		this.state ={
			workflowList:[],
			taskList:[],
			tasks:[],
			csvdata:[],
			file:'',
			editorState:'',
			personRole:'',
			personId:'',
			personName:'',
			personEmail:'',
			responsibleRole:[],
			responsiblePerson:[],
			persons:[],
			rolePerson:[],
			taskId:'',
			taskName:'',
			taskStatus:'',
			xDays:'',
			completedBefore:'',
			finalTask:'',
			doNotShare:'N',
			gotolink:'',
			checklist:[],
			sendMessage:'',
			synchronize:'',
			detailsNote:'',
			//wipNote:'',
			taskNote:'',
			taskWipNote:'',
			startDate:dayjs(new Date()),
			dueDate:dayjs(new Date()),
			workflowTasks:new Map(),
			wflowTasks:[],
			goLinks:[],
			categories:[],
			subcategories:[],
			gotocategories:[],
			gotosubcategories:[],
			wipTableNote:'',
			workflowName:'',
			workflowId:'',
			catId:'',
			subcatId:'',
			taskGuid:'',
			uniqueQrCode:'',
			uniqueGuid:'',
			daysCount:0,
			uploadedImages:[],
			status_change:'false',
			page:0,
			rowsPerPage:10,
			videoList:[],
			filterVideo:[],
			videoTags:[],
			showActive:true,
			showFinalTask:true,
			showFormIdSection:false,
			gotocatid:'',
			gotosubcatid:'',
			gotoTags:[],
			gotolinkValues:[],
			gotolinkValuesFilter:[],
			GtTag:'',
			deviceTypes:[],
			visitTypes:[],
			formtags:[],
			filterDeviceType:'',
			filterVisitType:'',
			filterTags:'',
		}
		
		this.csvLink = React.createRef();
		this.handleChange = this.handleChange.bind(this);
    }
	
	componentDidMount() {
		this.loadScript();
		this.getWorkflow();
		this.getTaskMasterUsers();
		this.getGotoLink();
		this.getCategories();
		this.getGotolinkCategories();
		this.getVideoFromApi();
	}
	
	handleChange = date => {
       // let newDate = (date.getMonth()+1)+'/'+date.getDate()+'/'+date.getFullYear();
        this.setState({
          startDate: date,date
        });
		//this.setState({dueDate:newDate});
    };
	
	loadScript() {
		var trigger2 = $('.fadeInRight'),
			overlay = $('.overlay'),
		   isClosed2 = false;
		
		function buttonSwitch2() {
			if (isClosed2 === true) {
				overlay.hide();
				trigger2.removeClass('is-open');
				trigger2.addClass('is-closed');
				isClosed2 = false;
			} else {
				overlay.show();
				trigger2.removeClass('is-closed');
				trigger2.addClass('is-open');
				isClosed2 = true;
			}
		}
		
		trigger2.click(function () {
			buttonSwitch2();
		});

		$('[data-toggle="offcanvas-3"]').click(function () {
			$('#wrapper2').toggleClass('toggled-3');
		});
		
		$(function() {
		  'use strict';
		  $('.js-menu-toggle').click(function(e) {
			var $this = $(this);
			if ( $('#wrapper').hasClass('toggled') ) {
				$('#wrapper').removeClass('toggled');
				$this.removeClass('active');
			} else {
				$('#wrapper').addClass('toggled');	
				$this.addClass('active');
			}
			e.preventDefault();
		  });
		});
		
	}
	
	getTaskMasterUsers=() => {
		
		let user = localStorage.getItem('username');
		
		let ApiUrl = $('#ApiUrl').val();
		let url = ApiUrl+'authors-responsible';
		let data = [];
		axios.get(url)
        .then(response => {
			data = response.data;
			
			let userDt = [];
		
			data.map(function(val,n) {
				if(user == val.user_name){
					userDt = val.responsible_role;
				}else if(val.id == 1){
					userDt = val.responsible_role;
				}
			})
			
			let rolePerson = [];
			let responsibleRole = Object.keys(userDt);
			
			responsibleRole.map(function(val,n) {
				let persons = userDt[val];
				let person = [];
				persons.map(function(vl,n) {
					person.push(vl.person+'|'+vl.person_id+'|'+vl.person_email);
				})
				
				rolePerson[val] = person.join(",");
				
			})
			
			this.setState({userData:data,responsibleRole,rolePerson});
			
		}).catch(error => {
			alert('error::'+ error);
		})
    }
	
	getGotoLink=() => {
		let ApiUrl = $('#ApiUrl').val();
		let url = ApiUrl+'gotolinks';
		let data = [];
		axios.get(url)
        .then(response => {
			data = response.data;
			
			this.setState({goLinks:data});
			let that = this;
			setTimeout(function(){
				that.getLinkTags();
			}, 1000);
			
		}).catch(error => {
			alert('error::'+ error);
		})
    }
	
	getLinkTags=()=>{
		
		let gotoTags=[];
		this.state.goLinks.map((row,i) => {
			
			if(this.state.gotocatid && this.state.gotocatid != row.category){
				return false;
			}
			
			if(this.state.gotosubcatid != '' && this.state.gotosubcatid != row.subcategory){
				return false;
			}
			
			let tags = row.tags.split(',');
			tags.map((tag,i) => {
				gotoTags.push(tag.trim());
			})
		})
		gotoTags = this.uniqueArray2(gotoTags);
		//console.log('gotoTags->',gotoTags);
		this.setState({gotoTags});
		
	}
	
	getWorkflow=()=>{
		let ApiUrl = $('#ApiUrl').val();
		let that = this;
		let url = ApiUrl+'workflows-details';
		let data = [];
		axios.get(url)
        .then(response => {
            data = response.data;
			let role = localStorage.getItem('role');
			let user_id = localStorage.getItem('user_id');
			let assignCompany = localStorage.getItem('userCompany');
			let newData = [];			
			data.map((val,i)=>{
				if(role == 'Admin'){
					if(val['user_id'] != user_id || val['company'] != assignCompany){
						return false;
					}
				}
				newData.push(val);
			});
			
			this.setState({workflowList:newData});
			if(!this.state.workflowName){
				this.setState({workflowId:newData[0]['id'],workflowName:newData[0]['name']});
			}
			setTimeout(function(){
				that.getTaskList();
			}, 1000);
        })
	}
	
	getTaskList=()=>{
		let ApiUrl = $('#ApiUrl').val();
		let id = $('#workflowOptionVal').val();
		
		let url = ApiUrl+'details-workflow-tasks/'+id;
		let data = [];
		
        axios.get(url)
        .then(response => {
            data = response.data;
			
			console.log('data->',data);
			let daysCount=0;
			let sTask = true;
			data.map((row,i) => {
				if(row.x_days_number){
					daysCount = parseInt(daysCount)+parseInt(row.x_days_number);
				}
				
				if(row.final_task == 'Y'){
					sTask = false;
				}
			})
			
			
			this.setState({taskList:data,daysCount:daysCount,showFinalTask:sTask});
        })
	}
	
	getCategories=()=>{
		let ApiUrl = $('#ApiUrl').val();
		let that = this;
		let url = ApiUrl+'workflow-categories';
		let data = [];
        axios.get(url)
        .then(response => {
            data = response.data;
			this.setState({categories:data});
        })
	}
	
	getSubCategories=(id)=>{
		let ApiUrl = $('#ApiUrl').val();
		let that = this;
		let url = ApiUrl+'workflow-sub-categories/'+id;
		let data = [];
		axios.get(url)
        .then(response => {
            data = response.data;
			this.setState({subcategories:data});
        })
	}
	
	uniqueArray(arr) {
		var a = [];
		for (var i=0, l=arr.length; i<l; i++)
			if (a.indexOf(arr[i]) === -1 && arr[i] !== '')
				a.push(arr[i]);
		return a;
	}
	
	changeCategory=(event)=>{
		let name = event.target.selectedOptions[0].text;
		let val = event.target.value;
		this.getTaskList();
		this.setState({workflowId:val,workflowName:name});
	}
	
	filterCategory=(event)=>{
		let catId = event.target.value;
		
		if(catId){
			this.setState({catId,taskList:[],subcatId:''});
			$('#workflowOptionVal').val('');
			this.getSubCategories(catId);
		}else{
			this.setState({catId:''});
		}
		
		let that = this;
		setTimeout(function(){
			that.getWorkflow();
		}, 1000);
	}
	
	filterSubCategory=(event)=>{
		let subcatId = event.target.value;
		
		if(subcatId){
			this.setState({subcatId,wflowTasks:[],taskList:[]});
			$('#workflowOptionVal').val('');
		}else{
			this.setState({subcatId:''});
		}
		
		let that = this;
		setTimeout(function(){
			that.getWorkflow();
		}, 1000);
	}

	getValue=(event)=>{
		let name = event.target.name;
		let res = event.target.value;
		
		this.setState({[event.target.name]:event.target.value});
		
		if($("input[name='status_change']").prop("checked") == true){
			this.setState({status_change:'true'});
		}else if($("input[name='status_change']").prop("checked") == false){
			this.setState({status_change:'false'});
		}
		
		if($("input[name='xDays']").prop("checked") == false){
			this.setState({xDays:'N'});
		}
		
		if($("input[name='completedBefore']").prop("checked") == false){
			this.setState({completedBefore:'N'});
		}
		
		if($("input[name='finalTask']").prop("checked") == false){
			this.setState({finalTask:'N'});
		}
		
		if($("input[name='doNotShare']").prop("checked") == false){
			this.setState({doNotShare:'Y'});
		}
		
		if($("input[name='sendMessage']").prop("checked") == false){
			this.setState({sendMessage:'N'});
		}
		
		if($("input[name='synchronize']").prop("checked") == false){
			this.setState({synchronize:'N'});
		}
		
		if(name == 'personRole'){
			let rolePerson = this.state.rolePerson;
			rolePerson = rolePerson[res].split(',');
			let personNames = [];
			rolePerson.map((val, i) => {
				//let name = val.split('|');
				//personName.push(name[0]);
				personNames.push(val);
			})
			this.setState({persons:rolePerson,responsiblePerson:personNames});
		}
		
		if(name == 'personName'){
			let index = event.target.selectedIndex;
			let el = event.target.childNodes[index]
			let opt =  el.getAttribute('data-id');
			
			if(opt){
				let person = opt.split('|');
				this.setState({personId:person[1],personName:person[0],personEmail:person[2]});
			}
		}
		
		if(name == 'xDaysNumber'){
			if(res > 90 || res < 0){
				alert('The value must be less than or equal to 90');
				this.setState({xDaysNumber:''});
			}
		}
		
		if(name == 'filterGotoCategory'){
			this.setState({gotocatid:res});
			this.getGotoSubCategories(res);
			this.getLinkTags();
			
		}
		
		if(name == 'filterGotoSubCategory'){
			this.setState({gotosubcatid:res});
			this.getLinkTags();
		}
		
		
		if(name == 'gotolink'){
			if(res.indexOf('{CustomerLayoutGUID}') != -1){
				this.setState({showFormIdSection:true});
			}else{
				this.setState({showFormIdSection:false});
			}
		}
		
		if(name == 'reqTasks' && res){
			res = Array.from(event.target.selectedOptions, (item) => item.value).join();
			let newArray = name+'Array';
			//console.log('newArray->',newArray);
			this.setState({[event.target.name]:res,[newArray]:Array.from(event.target.selectedOptions, (item) => item.value)});
		}
	}
	
	getGotoLinkValues=()=>{
		let ApiUrl = $('#ApiUrl').val();
		let that = this;
		let url = ApiUrl+'gotolink-values';
		let data = [];
		axios.get(url)
        .then(response => {
            data = response.data;
			this.setState({gotolinkValues:data});
			let that = this;
			setTimeout(function(){
				that.dataFilter();
			}, 1000);
			
        })
	}

	editTask=(data)=>{
		//this.getWipNote();
		this.getGotoLinkValues();
		$("#wrapper2").addClass('toggled-3');
		$(".fadeInRight").addClass('is-open');
		$(".fadeInRight").removeClass('is-closed');
		let taskId = data.id;
		$('.add-checklist').hide();
		
		let personRole = data.role != null ? data.role : '';
		
		if(personRole){
			let rolePerson = this.state.rolePerson;
			rolePerson = rolePerson[personRole].split(',');
			let personNames = [];
			rolePerson.map((val, i) => {
				//let name = val.split('|');
				//personNames.push(name[0]);
				personNames.push(val);
				//personId.push(name[1]);
				//personEmail.push(name[2]);
			})
			this.setState({persons:rolePerson,responsiblePerson:personNames});
		}else{
			this.setState({persons:[],responsiblePerson:[]});
		}
		
		let taskName = data.name != null ? data.name : '';
		let personName = data.person != null ? data.person : '';
		let personId = data.person_id != null ? data.person_id : '';
		let personEmail = data.person_email != null ? data.person_email : '';
		let startDate = data.due_date != null ? dayjs(data.due_date) : '';
		let xDays = data.x_days;
		let xDaysNumber = data.x_days_number != null ? data.x_days_number : '';
		let completedBefore = data.completed_before;
		let finalTask = data.final_task;
		let doNotShare = data.share;
		let gotolink = data.gotolink ? data.gotolink : '';
		let formGuid = data.form_guid ? data.form_guid : '';
		let sendMessage = data.send_message;
		let status_change = data.status_change;
		let checklist = data.checklist ? data.checklist.split(',') : [];
		let synchronize = data.synchronize;
		let taskGuid = data.uid;
		let uniqueGuid = data.unique_guid;
		let detailsNote = data.details_note != null ? data.details_note : '';
		//let wipNote = data.wip_note != null ? data.wip_note : '';
		//let workflowOption = data.task_option;
		if(formGuid != ''){
			this.setState({showFormIdSection:true});
		}else{
			this.setState({showFormIdSection:false});
		}
		
		let reqTasksArray = [];
		let req_tasks = data.req_tasks;
		if(req_tasks){
			let rqt = req_tasks.split(',');
			this.setState({reqTasksArray:rqt});
		}else{
			this.setState({reqTasksArray:[]});
		}
		
		$('.MuiOutlinedInput-input').val('');
		
		if(typeof detailsNote !== 'undefined' && detailsNote !== ''){
			let addNoteText = detailsNote;
			let imgUrl  = addNoteText;
			//const html = `<p>`+imgUrl+`</p>`;
			const contentBlock = htmlToDraft(detailsNote);

			if (contentBlock) {
				const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
				const editorState = EditorState.createWithContent(contentState);
				this.setState({
				  editorState,editorSourceHTML:addNoteText
				});
			}
			//this.setState({noteColor:'4px solid #9900ff',noteText:'Original Note',addNoteText1:imgUrl,addNoteText:addNoteText,});
		}else{
			this.setState({editorState:'',editorSourceHTML:''});
		}
		
		//console.log('data',data);
		let currentDate = new Date().toLocaleString("en-US", {timeZone: "America/New_York"});
		let cFormUID = data.form_id;
		this.setState({taskName,taskId,personRole,personName,personId,personEmail,startDate,dueDate:null,xDays,xDaysNumber,completedBefore,finalTask,doNotShare,gotolink,formGuid,sendMessage,synchronize,checklist,detailsNote,taskNote:'',taskWipNote:'',status_change,taskGuid,uniqueGuid});
	}
	
	updateWorkflowTask=()=>{
		
		let taskId = this.state.taskId;
		//console.log('taskId',taskId);
		let personRole = this.state.personRole;
		let personId = this.state.personId;
		let personName = this.state.personName;
		let personEmail = this.state.personEmail;
		let startDate = this.state.startDate;
		let xDays = this.state.xDays;
		let xDaysNumber = this.state.xDaysNumber;
		let completedBefore = this.state.completedBefore;
		let reqTasks = this.state.reqTasksArray.join();
		let finalTask = this.state.finalTask;
		let doNotShare = this.state.doNotShare;
		let gotolink = this.state.gotolink;
		let sendMessage = this.state.sendMessage;
		let synchronize = this.state.synchronize;
		//let detailsNote = this.state.detailsNote;
		let detailsNote = this.state.editorSourceHTML;
		let checklist = this.state.checklist.join();
		let status_change = this.state.status_change;
		let formGuid = this.state.formGuid;
		//let wipNote = this.state.wipNote;
		//let workflowOption = this.state.workflowOption;
		let ApiUrl = $('#ApiUrl').val();
		let url = ApiUrl+'update-workflow-tasks-v2';
		
		let formData = new FormData();
		formData.append('taskId', taskId);
		formData.append('personRole', personRole);
		formData.append('personId', personId);
		formData.append('personName', personName);
		formData.append('personEmail', personEmail);
		formData.append('startDate', startDate);
		formData.append('xDays', xDays);
		formData.append('xDaysNumber', xDaysNumber);
		formData.append('completedBefore', completedBefore);
		formData.append('reqTasks', reqTasks);
		formData.append('finalTask', finalTask);
		formData.append('sendMessage', sendMessage);
		formData.append('synchronize', synchronize);
		formData.append('detailsNote', detailsNote);
		//formData.append('wipNote', wipNote);
		formData.append('doNotShare', doNotShare);
		formData.append('gotolink', gotolink);
		formData.append('checklist', checklist);
		formData.append('status_change', status_change);
		formData.append('form_guid', formGuid);
		
		axios({
			method: 'POST',
			url: url,
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
		.then(response => {
			if(response.data){
				alert(response.data);
			}
			this.getTaskList();
		}).catch(error => {
			alert('error::'+ error);
		})
		
	}
	
	updateTaskOption=(event)=>{
		let id = event.target.id;
		let name = event.target.name;
		let val = event.target.value;
		
		let ApiUrl = $('#ApiUrl').val();
		let url = ApiUrl+'update-option';
		
		let formData = new FormData();
		formData.append('Id', id);
		formData.append('option', val);
		axios({
			method: 'POST',
			url: url,
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
		.then(response => {
			this.getTaskList();
		}).catch(error => {
			alert('error::'+ error);
		})
		
	}
	
	detailsNote=(note)=>{
		this.setState({taskNote:note});
	}
	
	showCheckList=(op)=>{
		if(op == true){
			$('.add-checklist').show();
		}else{
			$('.add-checklist').hide();
		}
	}
	
	handleCheckList=()=>{
		let checklist = this.state.checklist;
		//console.log('checkListOption->',this.state.checkListOption);
		let checkListOption = this.state.checkListOption+':false';
		checklist.push(checkListOption);
		this.setState({checklist,checkListOption:''});
	}
	
	editWorkflow=()=>{
		
		let workflowId = $('#workflowOptionVal').val();
		let workflowName = this.state.workflowName;
		
		if(workflowId && workflowName){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'update-workflow-name/'+workflowId;
			let formData = new FormData();
			formData.append('name', workflowName);
			axios({
				method: 'POST',
				url: url,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				this.getWorkflow();
			}).catch(error => {
				alert('error::'+ error);
			})
		}	
	}
	
	deleteCheckList=()=>{
		this.setState({checklist:[]});
	}
	
	deleteCheckListItem=(item)=>{
		let checklist = this.state.checklist;
		let updateChecklist = [];
		if(checklist.length > 0){
			checklist.map(function(val,i) {
				let str = val.split(':');
				if(str[0] != item){
					updateChecklist.push(val);
				}
			})
			this.setState({checklist:updateChecklist});
		}
	}
	
	getQrCode=()=>{
		let qrCode = "https://api.qrserver.com/v1/create-qr-code/?size=150x150&data="+this.state.uniqueGuid;
		this.setState({uniqueQrCode:qrCode,copyQrCode:this.state.uniqueGuid});
	}
	
	copyQrImage=(img)=>{
		let path = "https://api.qrserver.com/v1/create-qr-code/?size=150x150&data="+img;
		copyImageToClipboard(path).then(() => {
		  console.log('Image Copied');
		}).catch((e) => {
		  console.log('Error: ', e.message);
		})
	}
	
	saveQrImage=(img,fileName)=>{
		let url = "https://api.qrserver.com/v1/create-qr-code/?size=150x150&data="+img;
		var xhr = new XMLHttpRequest();
		xhr.open("GET", url, true);
		xhr.responseType = "blob";
		xhr.onload = function(){
			var urlCreator = window.URL || window.webkitURL;
			var imageUrl = urlCreator.createObjectURL(this.response);
			var tag = document.createElement('a');
			tag.href = imageUrl;
			tag.download = fileName+'.png';
			document.body.appendChild(tag);
			tag.click();
			document.body.removeChild(tag);
		}
		xhr.send();
	}
	
	printQrImage=()=>{
		let w = window.open();
		let html = $("#qr-code-img").html();
		$(w.document.body).html(html);
		setTimeout(function(){w.print()}, 1000);
	}
	
	onEditorStateChange=(editorState)=>{
        var convertedData = editorState.getCurrentContent()
        let d =  convertToRaw(editorState.getCurrentContent()) 
        let editorSourceHTML = draftToHtml(d)
        this.setState({
            editorState,editorSourceHTML
        });
    }
	
    _uploadImageCallBack=(file)=>{
        let uploadedImages = this.state.uploadedImages;
        const imageObject = {
            file: file,
            localSrc: URL.createObjectURL(file),
        }
        uploadedImages.push(imageObject);
        this.setState({uploadedImages: uploadedImages})
        
		//let url = this.state.ApiUrl+'notefile/file_upload.php';
		let ApiUrl = $('#mongoApiUrl').val();
		let url = ApiUrl+'image-upload';
		//let ApiUrl = this.state.ApiUrl;
		return new Promise(
		(resolve, reject) => {
			var form_data = new FormData();
			form_data.append('file', file);
			axios({
				method: 'POST',
				url: url,
				data: form_data,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				let rlink = ApiUrl+response.data
				resolve({ data: { link: rlink } });
			})
		});
    }
	
	handleChangePage = (event: unknown, newPage: number) => {
		this.setState({page:newPage});
	};

	handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({rowsPerPage:event.target.value,page:0});
	};
	
	uniqueArray2(arr) {
		var a = [];
		for (var i=0, l=arr.length; i<l; i++)
			if (a.indexOf(arr[i]) === -1 && arr[i] !== '')
				a.push(arr[i]);
		return a;
	}
	
	getVideoFromApi=()=>{
		let ApiUrl = $('#VideoApiUrl').val();
		let ApiKey = $('#VideoApiKey').val();
		axios({
			method: 'GET',
			url: ApiUrl,
			headers: {
				'Content-Type': 'application/json',
				'x-api-key': ApiKey,
			}
		})
		.then(response => {
			const data = response.data;
			let videoTags = [];
			//console.log('data::', data);
			data.map((tgs,i) =>{
				if(tgs.tags){
					tgs.tags.map((val,n) =>{
						videoTags.push(val)
						this.setState({[val]:true});
					});
				}
			});
			videoTags = this.uniqueArray2(videoTags);
			
			//console.log('videoTags::', videoTags);
			this.setState({videoList:data,videoTags:videoTags});
			let that = this;
			setTimeout(function(){
				that.filterVideoList();
			}, 1000);
			
		}).catch(error => {
			console.log('error::', error);
		})
	}
	
	filterVideoList=()=>{
		let videoList = this.state.videoList;
		let videoTags = this.state.videoTags;
		let filterVideo = this.state.filterVideo;
		let filterVd = [];
		videoTags.map((tag, i) => {
			if($("input[name='"+tag+"']").prop("checked") == true){
				this.setState({[tag]:true});
				
				videoList.map((video, i) => {
					if(video.tags.indexOf(tag) != -1){
						filterVd[video._id] = video;
					}
				})
			}
			if($("input[name='"+tag+"']").prop("checked") == false){
				this.setState({[tag]:false});
			}
		})
		
		filterVd =  Object.values(filterVd);
		this.setState({filterVideo:filterVd});
	}
	
	insertVideo=()=>{
		//this.getVideoFromApi();
		$('#videoList').modal('show')
	}
	
	inserEmbedCode=(embedcode)=>{
		let editorState = this.state.editorState;
		let editorSourceHTML = this.state.editorSourceHTML;
		
		//this.setState({editorState:'',editorSourceHTML:''});
		
		let addNoteText = editorSourceHTML;
		
		const html = addNoteText+'<p>'+embedcode+'</p><p></p>';

		const contentBlock = htmlToDraft(html);
		if (contentBlock) {
			const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
			const editorState = EditorState.createWithContent(contentState);
			this.setState({
			  editorState,editorSourceHTML:html
			});
			
			$('#videoList').modal('hide');
		}
	}
	
	previewEmbedCode=(embed)=>{
		//this.setState({previewCode:embed});
		$('#videoPreview').modal('show');
		$('#preView').html(embed);	
	}
	
	filterWorkflow=(e)=>{
		this.setState({showAll:false,showActive:false,showInActive:false});
		let filter = e.target.name;
		if($("input[name='"+filter+"']").prop("checked") == true){
			this.setState({[filter]:true});
		}
		let that = this;
		setTimeout(function(){
			that.getTaskList();
		}, 1000);
	}
	
	outputPdf=(taskList)=>{
		let workflowList = this.state.workflowList;
		let workflowId = taskList[0].workflow_id;
		let currentDate = new Date().toLocaleString("en-US").replace(',','');
		let workflowData = [];
		workflowList.map((val, i) => {
			
			if(val['id'] == workflowId){
				workflowData = val;
			}
		})
		
		let catName = '';
		this.state.categories.map((val, i) => {
			if(val.id == workflowData['category']){
				catName = val.name
			}
			
		})
		let subcatName = '';
		this.state.subcategories.map((val, i) => {
			if(val.id == workflowData['subcategory']){
				subcatName = val.name
			}
		})
		
		//const doc = new jsPDF();
		
		const doc = new jsPDF({
		  orientation: "landscape"
		});
		
		autoTable(doc, {
			/* headerStyles: {
				lineWidth: {
					bottom: 1,
				},
				lineColor: 'black',
			}, */
			styles: {
				cellPadding: 0.5,
				fontSize: 12
			},
			theme: ['plain'],
			tableWidth: 150,
			head: [['Workflow Name:', workflowData['name'],'',''],],
			body: [
				['Workflow Category:', catName,'',''],
				['Workflow Subcategory:', subcatName,'',''],
				['Effective Date:', currentDate,'',''],
			],
		})
		
		doc.setFontSize(16);
		
		doc.autoTable({
			styles: {
				cellPadding: 1,
				fontSize: 10
			},
			html: '#my-table',
			didParseCell: function (data) {
				data.cell.styles.fillColor = '#fff';
				if (data.cell.raw){
					if(data.cell.raw.localName == 'th' && data.row.index > 0) {
						data.cell.styles.fontStyle = 'bold';
						data.cell.styles.fontSize = 14;
					}
				}
				
			},
			willDrawCell: function(data) {
				if (data.row.index === 0) {
					doc.setFontSize(12);
				}

				if (data.row.index === 1) {
				  doc.setDrawColor('#000000'); 
				  doc.setLineWidth(1); 
				  doc.line(
					data.cell.x,
					data.cell.y,
					data.cell.x + data.cell.width,
					data.cell.y
				  );
				}
				
				if (data.row.raw[0].colSpan === 8) {
					doc.setDrawColor('#000000');
					doc.setLineWidth(1);
					doc.line(
						data.cell.x,
						data.cell.y,
						data.cell.x + data.cell.width,
						data.cell.y
					);
				}
			}
		});
		doc.output('dataurlnewwindow');
		//doc.save("a4.pdf");
	
	}
	
	getGotolinkCategories=()=>{
		let ApiUrl = $('#ApiUrl').val();
		let that = this;
		let url = ApiUrl+'gotolink-categories';
		let data = [];
        axios.get(url)
        .then(response => {
            data = response.data;
			this.setState({gotocategories:data});
        })
	}
	
	getGotoSubCategories=(id)=>{
		let ApiUrl = $('#ApiUrl').val();
		let that = this;
		let url = ApiUrl+'gotolink-subcategories/'+id;
		let data = [];
		axios.get(url)
        .then(response => {
            data = response.data;
			this.setState({gotosubcategories:data});
        })
	}
	
	filterFormName=(event)=>{
		let name = event.target.name;
		let res = event.target.value;
		this.setState({[event.target.name]:event.target.value});
		//let res = Array.from(event.target.selectedOptions, (item) => item.value);
		//this.setState({[event.target.name]:res});
		let that = this;
		setTimeout(function(){
			that.dataFilter();
		}, 1000);
	}
	
	dataFilter=() => {
		let gotolinkValuesFilter = [];
		let that = this;
		let deviceTypesArr = [];
		let visitTypesArr = [];
		let tagsArr = [];
		this.state.gotolinkValues.map(function(row,i) {
			
			/* if(that.state.filterCategory && that.state.filterCategory != row.category){
				return false;
			}
			
			if(that.state.filterSubCategory && that.state.filterSubCategory != row.subcategory){
				return false;
			} */
			
			let dTypes = row.associated_device_types.split(',');
			dTypes.map(function(ty,i) {
				deviceTypesArr.push(ty.trim());
			});
			
			//console.log('dTypes->',dTypes);
			//console.log('filterDeviceType->',that.state.filterDeviceType);
			
			if(that.state.filterDeviceType && dTypes.indexOf(that.state.filterDeviceType) == -1){
				return false;
			}
			
			/* if(that.state.filterDeviceType.length > 0){
				let flt = true;
				that.state.filterDeviceType.map(function(ty,i) {
					if(dTypes.indexOf(ty) != -1){
						flt = false;
					}
				});
				if(flt){
					return false;
				}
			} */
			
			let vTypes = row.associated_visit_types.split(',');
			vTypes.map(function(ty,i) {
				visitTypesArr.push(ty.trim());
			});
			
			if(that.state.filterVisitType && vTypes.indexOf(that.state.filterVisitType) == -1){
				return false;
			}
			
			/* if(that.state.filterVisitType.length > 0){
				let fltl = true;
				that.state.filterVisitType.map(function(ty,i) {
					if(vTypes.indexOf(ty) != -1){
						fltl = false;
					}
				});
				if(fltl){
					return false;
				}
			} */
			
			let tags = row.tags.split(',');
			tags.map(function(tg,i) {
				tagsArr.push(tg.trim());
			});
			
			if(that.state.filterTags && tags.indexOf(that.state.filterTags) == -1){
				return false;
			}
			
			/* if(that.state.filterTags.length > 0){
				let fltl = true;
				that.state.filterTags.map(function(ty,i) {
					if(tags.indexOf(ty) != -1){
						fltl = false;
					}
				});
				if(fltl){
					return false;
				}
			} */
			
			/* if(that.state.filterStatus && that.state.filterStatus != row.status){
				return false;
			}  */
			
			gotolinkValuesFilter.push(row);
		});
		
		let deviceTypes = this.uniqueArray(deviceTypesArr);
		let visitTypes = this.uniqueArray(visitTypesArr);
		let formtags = this.uniqueArray(tagsArr);
		
		this.setState({gotolinkValuesFilter,formtags,deviceTypes,visitTypes});
	}
	
	render() {
		
		const {workflowList,taskList,workflowTasks,wflowTasks,responsibleRole,responsiblePerson,goLinks,categories,subcategories,catId,subcatId,checklist,videoList,filterVideo,videoTags,gotocategories,gotosubcategories,gotocatid,gotosubcatid,gotoTags,GtTag,gotolink,filterGotoType,showFormIdSection,gotolinkValues,gotolinkValuesFilter,formtags,deviceTypes,visitTypes} = this.state;

		let that = this;
		//console.log('taskId->',that.state.taskId);
		
		let formIdHtml = gotolinkValuesFilter.map(function(val,i) {
			return (
				<option value={val.layout_guid} key={i}>{val.layout_name}</option>
			);
		})
		
		let resTasks = taskList.map(function(val,i) {
			let taskId = that.state.taskId;
			if(taskId != val.id){
				return (
					<option value={val.id} key={i}>{val.name}</option>
				);
			}
		})
		
		let categoriesHtml = categories.map(function(val,i) {
			return (
				<option value={val.id} key={i}>{val.name}</option>
			);
		})
		
		let subcategoriesHtml = subcategories.map(function(val,i) {
			return (
				<option value={val.id} key={i}>{val.name}</option>
			);
		})
		
		let editCategoryOption = gotocategories.map(function(val,i) {
			return (
				<option value={val.id} key={i}>{val.name}</option>
			);
		})
		
		let editSubCategoryOption = gotosubcategories.map(function(val,i) {
			return (
				<option value={val.id} key={i}>{val.name}</option>
			);
		})
		
		let gotolinkTags = gotoTags.map(function(val,i) {
			return (
				<option value={val} key={i}>{val}</option>
			);
		})
		
		let formTags = formtags.map(function(val,i) {
			return (
				<option value={val} key={i}>{val}</option>
			);
		})
		
		let formdeviceTypes = deviceTypes.map(function(val,i) {
			return (
				<option value={val} key={i}>{val}</option>
			);
		})
		
		let formvisitTypes = visitTypes.map(function(val,i) {
			return (
				<option value={val} key={i}>{val}</option>
			);
		})
		
		let role = localStorage.getItem('role');
		
		let userId = localStorage.getItem('user_id');
		let assoTags = [];
		let workflowOption = workflowList.map(function(val,i) {
			
			if(that.state.showActive){
				if(val.action == 0){
					return false;
				}
			}else if(that.state.showInActive){
				if(val.action == 1){
					return false;
				}
			}
	
			if(catId && catId != val.category){
				return false;
			}

			if(subcatId != '' && subcatId != val.subcategory){
				return false;
			}
			
			if(role == 'OA' && val.user_id == userId){
				return (
					<option value={val['id']} key={i}>{val['name']}</option>
				);
			}else if(role != 'OA'){
				return (
					<option value={val['id']} key={i}>{val['name']}</option>
				);
			}
		})
		let thats = this;
		let checklistHtml = checklist.map(function(val,i) {
			let listtext = val.split(':');
			
			return (
				<label className="label-list">
				<input name="demo" type="checkbox" value={listtext[0]} disabled/> {listtext[0]}
				<span className="delete-checklist-item" onClick={() => {if (window.confirm('Are you sure you want to Delete this checklist item?')) thats.deleteCheckListItem(listtext[0])}} ><i className="fa fa-trash"></i></span>
				</label>						
			);
		})
		
		let goLinksHtml = goLinks.map(function(val,i) {
			
			if(filterGotoType && filterGotoType != val.type){
				return false;
			}
			
			if(gotocatid && gotocatid != val.category){
				return false;
			}
			
			if(gotosubcatid != '' && gotosubcatid != val.subcategory){
				return false;
			}
			
			let tags = val.tags;
			
			if(GtTag != '' && tags.indexOf(GtTag) == -1){
				return false;
			}
			
			return (
				<option key={i} value={val.url}>{val.name}</option>
			);
		})
		
		let personOption = responsiblePerson.map(function(val,i) {
			let name = val.split('|');
			return (
				<option key={i} data-id={val} value={name[0]}>{name[0]}</option>
			);
		})
		

		let href = window.location.href.split('?')[0];
		//console.log('checklist->',this.state.checklist);
		let wName = $('#workflowOptionVal option:selected').text();
		return (
			<div id="wrapper2">
				<aside className="sidebar">
				  <div className="toggle toggle-btn">
					<a href="#" className="burger js-menu-toggle active" data-toggle="collapse" data-target="#main-navbar">
						  <span></span>
						</a>
				  </div>
				  <div className="side-inner align-items-end" >
					<div className="sidebar-content">
						<div className="nav-content">
							<div className="panel-btn">
							
								<h6 className="page-title">Filter Workflows</h6>
							
								<div className="input-group mb-3">
									{ categoriesHtml ?
										<select id='filterCategory' className="form-control" name="filterCategory" onChange={this.filterCategory}>
											<option value=''>Select Workflow Category</option>
											{categoriesHtml}
										</select>
									:null}
								</div>
								{ subcategoriesHtml.length > 0 && catId ?
								<div className="input-group mb-3">
									<select id='filterSubCategory' className="form-control" name="filterSubCategory" onChange={this.filterSubCategory}>
										<option value=''>Select Workflow Subcategory</option>
										{subcategoriesHtml}
									</select>
									
								</div>
								:null}
								
								<div className="input-group mb-3">
									
									<div className="tpm-filter">
										<div className="left-check-box"><input name="showAll" type="checkbox" onChange={this.filterWorkflow} checked={this.state.showAll ? 'checked' : ''}/> Show All</div>
										<div className="left-check-box"><input name="showActive" type="checkbox" onChange={this.filterWorkflow} checked={this.state.showActive ? 'checked' : ''} /> Show Active Only</div>
									</div>
									
									<div className="tpm-filter-2">
										<div className="left-check-box"><input name="showInActive" type="checkbox" onChange={this.filterWorkflow} checked={this.state.showInActive ? 'checked' : ''}/> Show Inactive Only</div>
									</div>
								</div>
								
								<div className="input-group mb-3">
									<select id='workflowOptionVal' className="form-control" name="workflowOption" onChange={this.changeCategory}>
										{workflowOption}
									</select>
									
								</div> 
								
							</div>
							
						</div>
					</div>
				  </div>
				</aside>
				<div className="task-list-workflow">
					<div className="row">
						<div className="col-md-12 mb-4">
							<h4>Workflow Details</h4>
						</div>
					</div>
					<div className="row">	
						<div className="col-md-12 mt-2">	
							{taskList.length > 0 ?
							<div id="listTasks" className="tasks-header-div">
								{wName != '' ?
								<h6 className="wname-day-count">{wName} ({this.state.daysCount} Days)</h6>
								:null}
								<button type="button" className="btn btn-info output-pdf" onClick={()=>this.outputPdf(taskList)}>Output as PDF</button>
								<Paper sx={{ width: '100%', overflow: 'hidden' }}>
								<TableContainer>
								  <Table size="small" aria-label="a dense table">
									<TableBody>
									 
									 { taskList.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((value) => {
											let className = 'light-yellow';
											if(value.status == 'Task'){
												className = 'light-green';
											}else if(value.status == 'Subtask'){
												className = 'light-blue';
											}
											
											let icon = 'x';
											if(value.task_option == 'To Do'){
												icon = 'red';
											}else if(value.task_option == 'In Progress'){
												icon = 'yellow';
											}else if(value.task_option == 'Complete'){
												icon = 'green';
											}else if(value.task_option == 'Pending'){
												icon = 'pending';
											}
											
											let days = 0;
											if(value.x_days_number > 1){
												days = value.x_days_number+' Days';
											}else{
												days = value.x_days_number+' Day';
											}
												
											return (<TableRow className={'task-div '+className} key={value.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
												<TableCell className="task-name-c2">{value.name}</TableCell>
												<TableCell>{value.role}</TableCell>
												<TableCell>{value.person}</TableCell>
												<TableCell>{value.Type}</TableCell>
												<TableCell>{value.due_date && value.due_date != 'null' ? moment(value.due_date).format('MM/DD/YYYY') : ''}</TableCell>
												<TableCell>
												<FormControl sx={{ m: 1, minWidth: 120, maxWidth: 120 }} size="small">
													<Select
														value={value.task_option}
														name={'taskOption'}
														onChange={this.updateTaskOption}
														style={{ width: 140,height: 28,background: '#fff' }}
													>
													<MenuItem value={''} >None</MenuItem>
													<MenuItem value={'Pending'} >Pending</MenuItem>
													<MenuItem value={'To Do'} >To Do</MenuItem>
													<MenuItem value={'In Progress'} >In Progress</MenuItem>
													<MenuItem value={'Complete'} >Complete</MenuItem>
													<MenuItem value={'N/A'} >N/A</MenuItem>
													</Select>
												</FormControl>
												</TableCell>
												<TableCell>{value.x_days_number}</TableCell>
												<TableCell className="right-side-btn">
													<span className="task-icon"><img src={href+'/'+icon+'.png'} alt="Status" width="18" height="18" /></span>
											
													{value.share == 'Yes' ?
														<span className="task-icon"><img src={href+'/icon-unlock.png'} alt="Share" width="15" height="18" /></span>
													:
														<span className="task-icon"><img src={href+'/lock.png'} alt="Share" width="15" height="18" /></span>
													}
													
													{value.send_message == 'Y' ?
														<span className="task-icon"><img src={href+'/send-message.png'} alt="Send Message" width="18" height="18" /></span>
													:null}
													{value.checklist != '' ?
														<span className="task-icon"><img src={href+'/Icon-Checklist.png'} alt="Send Message" width="18" height="18" /></span>
													:null}
													
													{value.synchronize == 'Y' ?
														<span className="task-icon"><img src={href+'/icon-sync.png'} alt="Synchronize" width="18" height="18" /></span>
													:null}
													
													
													{value.details_note != null && value.details_note != 'null' && value.details_note != '' ?
														<span className="task-icon task-note" data-toggle="modal" data-target="#taskNote" onClick={() => { this.detailsNote(value.details_note) } }><img src={href+'/icon-note.png'} alt="Details Note" width="18" height="18"/></span>
													:null}
													
													{/* value.wip_note != null && value.wip_note != 'null' && value.wip_note != '' ?
														<span className="task-icon task-wip-note" data-toggle="modal" data-target="#taskWipNote" onClick={() => { this.wipNote(value.wip_note) } }><img src={href+'/wip.png'} alt="WIP Note" width="18" height="18"/></span>
													:null */}
													
													{value.gotolink != null && value.gotolink != 'null' && value.gotolink != '' ?
														<span className="task-icon"><a href={value.gotolink} target="_blank" className="task-icon task-link-note"><img src={href+'/gotolink.png'} alt="Go to link" width="18" height="18"/></a></span>
													:null}
													
													<span className="task-icon"><button type="button" className="btn code-dialog btn-edit task-edit" onClick={()=>this.editTask(value)}><img src={href+'/Icon-Edit.png'} alt="edit" width="18" height="18"/></button></span>
												</TableCell>
											</TableRow>);
										})
									 }
									</TableBody>
								  </Table>
									<TablePagination
										rowsPerPageOptions={[5, 10, 20, 50, 100]}
										component="div"
										count={taskList.length}
										rowsPerPage={this.state.rowsPerPage}
										page={this.state.page}
										onPageChange={this.handleChangePage}
										onRowsPerPageChange={this.handleChangeRowsPerPage}
									/>
								</TableContainer>
								</Paper>
								
								<div className="modal" id={"taskNote"} role="dialog">
									<div className="modal-dialog modal-lg custom-modal mds-description-modal">
										<div className="modal-content">
										  <div className="modal-header">
											<h5 className="modal-title">Details Note</h5>
											<button type="button" className="close" data-dismiss="modal">&times;</button>
										  </div>
										  <div className="modal-body">
										  {parse(this.state.taskNote)}
										  </div>
										  <div className="modal-footer">
												<div className="popup-btn-com">
													<button type="button" className="btn btn-danger float-right" data-dismiss="modal">Close</button>
												</div>
										  </div>
										</div>
									</div>
								</div>
								<div className="modal" id={"taskWipNote"} role="dialog">
									<div className="modal-dialog modal-lg custom-modal mds-description-modal">
										<div className="modal-content">
										  <div className="modal-header">
											<h5 className="modal-title">WIP Note</h5>
											<button type="button" className="close" data-dismiss="modal">&times;</button>
										  </div>
										  <div className="modal-body">
										  {this.state.taskWipNote}
										  </div>
										  <div className="modal-footer">
												<div className="popup-btn-com">
													<button type="button" className="btn btn-danger float-right" data-dismiss="modal">Close</button>
												</div>
										  </div>
										</div>
									</div>
								</div>
								<div className="modal" id={"editWorkflow"} role="dialog">
									<div className="modal-dialog modal-lg custom-modal mds-description-modal">
										<div className="modal-content">
										  <div className="modal-header">
											<h5 className="modal-title">Update Workflow Name</h5>
											<button type="button" className="close" data-dismiss="modal">&times;</button>
										  </div>
										  <div className="modal-body">
											<input className="form-control" name="workflowName" type="text" value={this.state.workflowName} onChange={this.getValue} />
										  </div>
										  <div className="modal-footer">
												<div className="popup-btn-com">
													<button type="button" className="btn btn-danger float-right" data-dismiss="modal">Close</button>
													<button type="button" onClick={()=>this.editWorkflow()} className="btn btn-info float-right mr-1" data-dismiss="modal">Update</button>
												</div>
										  </div>
										</div>
									</div>
								</div>
								
							</div>
							:null}
						</div>
					</div>
					<button type="button" className="hamburger animated fadeInRight is-closed" data-toggle="offcanvas-3"></button>
				</div>
				<nav className="custom-navbar-2" id="sidebar-wrapper-right" role="navigation">
					<div className="nav-content-right">
						<h5 className="page-title">Details Panel</h5>
						<div className="workflow-item-fields">
							<div className="row-input">
								<label className="label-control"> Responsible Role </label>
								<select className="form-control" name="personRole" onChange={this.getValue} value={this.state.personRole}>
									<option value="">Please Select</option>
									{responsibleRole.map((val, i) => (
										<option key={i} value={val}>{val}</option>
									))}
								</select>
							</div>
							<div className="row-input">
								<label className="label-control"> Responsible Person </label>
								<select className="form-control" name="personName" onChange={this.getValue} value={this.state.personName}>
									<option value="">Please Select</option>
									{personOption}
								</select>
							</div>
							
							<div className="row-input-date">
								<label className="label-control"> Select due date </label>
								<LocalizationProvider dateAdapter={AdapterDayjs}>
								 <DatePicker 
									value={this.state.startDate ? this.state.startDate : this.state.dueDate}
									onChange={this.handleChange}
								  />
								</LocalizationProvider>
								
							</div>
							
							<div className="workflow-checkbox">
								<input name="xDays" type="checkbox" value="Y" checked={this.state.xDays == 'Y' ? true : false} onChange={this.getValue} /> This task must be completed in X days once started
								{this.state.xDays == 'Y' ?
									<input className="form-control x-days-number" name="xDaysNumber" min="0" max="99" type="number" value={this.state.xDaysNumber} onChange={this.getValue} />
								:null}
							</div>
							{this.state.showFinalTask || this.state.finalTask == 'Y' ?
							<div className="workflow-checkbox">
								<input name="finalTask" type="checkbox" value="Y" checked={this.state.finalTask == 'Y' ? true : false} onChange={this.getValue} /> This is a Final task
							</div>
							:null}
							<div className="workflow-checkbox">
								<input name="completedBefore" type="checkbox" value="Y" checked={this.state.completedBefore == 'Y' ? true : false} onChange={this.getValue} /> This task requires previous task to be completed before starting
							</div>
							
							<div className="workflow-checkbox">
								<input name="sendMessage" type="checkbox" value="Y" checked={this.state.sendMessage == 'Y' ? true : false} onChange={this.getValue} /> Send message to responsible person when task should be started
							</div>
							
							<div className="workflow-checkbox">
								<input name="synchronize" type="checkbox" value="Y" checked={this.state.synchronize == 'Y' ? true : false} onChange={this.getValue} /> Synchronize this status when updated in WIP
							</div>
							
							<div className="row-input">
								<label className="label-control"> Requires tasks to be completed before starting </label>
								<select className="form-control" name="reqTasks" onChange={this.getValue} value={this.state.reqTasksArray} multiple>
									{resTasks}
								</select>
							</div>
							
							<div className="row-input text-box">
								<label className="label-control">Task Details Note</label>
								<button type="button" className="btn btn-primary btn-insert-video" onClick={this.insertVideo}>Insert Video</button>
								<span className="do-not-share"><input name="doNotShare" type="checkbox" value="N" checked={this.state.doNotShare == 'N' ? true : false} onChange={this.getValue} /> Do not share</span>
								
								{/* <textarea id="detailsNote" name="detailsNote" rows="4" cols="50" onChange={this.getValue} value={this.state.detailsNote}></textarea> */}
								
								{/* <Editor
									editorState={this.state.editorState}
									toolbarClassName="toolbarClassName"
									wrapperClassName="wrapperClassName"
									wrapperStyle={{ border: "1px solid black", marginBottom: "20px" }}
									editorStyle={{ height: "220px", padding: "10px",maxWidth:'100%',maxHeight:'100%'}}
									editorClassName="editorClassName"
									onEditorStateChange={this.onEditorStateChange}
									toolbar={{
									inline: { inDropdown: true },
									list: { inDropdown: true },
									textAlign: { inDropdown: true },
									link: { inDropdown: true },
									history: { inDropdown: true },
									//image: { uploadCallback: this._uploadImageCallBack, alt: { present: true, mandatory: false },previewImage: true },
									inputAccept: 'application/pdf,text/plain,application/vnd.openxmlformatsofficedocument.wordprocessingml.document,application/msword,application/vnd.ms-excel'
									}}
								/> */}
								
							</div>
							{/* <div className="row-input">
								<label className="label-control">WIP Notes</label>
								<div className="add-wip-note">
									<input className="form-control input-wip-note" name="addWipNote" value={this.state.addWipNote} onChange={this.getValue} />
									<button type="button" className="btn btn-info btn-wip-note" onClick={this.handleWipNote}>Add</button>
								</div>
								<div id="wipNote" className="wip-Note-box"></div>
							</div> */}
							
							<div className="row-input list-sec">
								<div><label className="label-control" onClick={()=>this.showCheckList(true)}><input name="demo" type="checkbox" value="Y" checked='checked' disabled/> Checklist</label>
								<span className="change-status-com">
								<button onClick={this.getQrCode} data-toggle="modal" data-target="#qrCodePopup" className="btn qr-code-btn" type="button"><i className="fa fa-qrcode" aria-hidden="true"></i></button> Generate QR code for this taks
								</span>
								</div>
								<div className={checklist.length > 0 ? 'has-checklist add-checklist' : "add-checklist"} style={{display:'none'}}>
								    {/* this.state.checklist.length > 0 ?
								    <div className="delete-checklist" onClick={() => {if (window.confirm('Are you sure you want to Delete this checklist?')) this.deleteCheckList()}} ><i className="fa fa-trash"></i></div>
									:null */}
									<div className="check-list">
										{checklistHtml}
									</div>
									<div className="checklist-add">
									<input className="form-control input-checklist" name="checkListOption" value={this.state.checkListOption} onChange={this.getValue} placeholder="Add an item" />
									<button type="button" className="btn btn-info btn-check-list btn-sm" onClick={this.handleCheckList}>Add</button>&nbsp;
									<button type="button" className="btn btn-danger btn-check-list btn-sm" onClick={()=>this.showCheckList(false)}>Cancel</button>
									<span className="change-status-com"><input name="status_change" type="checkbox" value="Y" onChange={this.getValue} checked={this.state.status_change == 'true' ? true : false} /> Status to complete when 100%</span>
									
									</div>
								</div>
							</div>
							
							<div className="row-input">
								<label className="label-control"> Go To Link </label>
								
								<div className="input-group mb-3">
									<select className="form-control builder-filter" name="filterGotoType" onChange={this.getValue}>
										<option value={''}>Select Link Type</option>
										<option value={'Static'}>Static</option>
										<option value={'Dynamic'}>Dynamic</option>
										
									</select>
								</div>
								
								<div className="input-group mb-3">
									<select className="form-control builder-filter" name="filterGotoCategory" onChange={this.getValue}>
										<option value={''}>Select Category</option>
										{editCategoryOption}
									</select>
								</div>
								
								{gotosubcategories.length > 0 ?
								<div className="input-group mb-3">
									<select className="form-control builder-filter" name="filterGotoSubCategory" onChange={this.getValue}>
										<option value={''}>Select Subcategory</option>
										{editSubCategoryOption}
									</select>
								</div>
								:null}
								
								{gotoTags.length > 0 ?
								<div className="input-group mb-3">
									<select className="form-control builder-filter" name="GtTag" onChange={this.getValue}>
										<option value={''}>Select tag</option>
										{gotolinkTags}
									</select>
								</div>
								:null}
								
								<div className="input-group mb-3">
								<select className="form-control" name="gotolink" onChange={this.getValue} value={this.state.gotolink}>
									<option value="">Please Select Goto Link</option>
									{goLinksHtml}
								</select>
								</div>
								
							</div>
							{showFormIdSection ? 
							<div className="row-input">
								<label className="label-control"> FormID Configuration </label>
								
								{/* <div className="input-group mb-3">
									<select className="form-control" name="formCatgory" onChange={this.getValue}>
										<option value="">Please Form Category</option>
									</select>
								</div>
								<div className="input-group mb-3">
									<select className="form-control" name="formSubcatgory" onChange={this.getValue}>
										<option value="">Please Form Subcategory</option>
									</select>
								</div> */}
								
								{deviceTypes.length > 0 ?
								<div className="input-group mb-3">
									<select className="form-control" name="filterDeviceType" onChange={this.filterFormName} >
										<option value={''}>Select Device Type</option>
										{formdeviceTypes}
									</select>
								</div>
								:null}
								
								{visitTypes.length > 0 ?
								<div className="input-group mb-3">
									<select className="form-control" name="filterVisitType" onChange={this.filterFormName} >
										<option value={''}>Select Visit Type</option>
										{formvisitTypes}
									</select>
								</div>
								:null}
								
								{formtags.length > 0 ?
								<div className="input-group mb-3">
									<select className="form-control" name="filterTags" onChange={this.filterFormName} >
										<option value={''}>Select tag</option>
										{formTags}
									</select>
								</div>
								:null}
								
								<div className="input-group mb-3">
									<select className="form-control" name="formGuid" onChange={this.getValue} value={this.state.formGuid}>
										<option value="">Please Form Name</option>
										{formIdHtml}
									</select>
								</div>
								
								
							</div>
							:null}
							<div className="row-input-save">
								<button type="button" className="btn btn-info btn-update-status" onClick={this.updateWorkflowTask}>Save</button>
							</div>
						</div>
					</div>
				</nav>
				
				<div className="modal" id={'videoList'}>
					<div className="modal-dialog modal-lg modal-sm select-modal modal-dialog-2">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Select a video to insert into note </h5>
								<button type="button" className="close" data-dismiss="modal">&times;</button>
							</div>
							<div className="modal-body video-list-div">
								
								<div className="tag-section">
								<h6 className="modal-title">Limit video list by selecting or removing tags</h6>
								{videoTags.map((val, i) => (
									<div className="v-tag"><input name={val} type="checkbox" checked={this.state[val] ? true : false}  onChange={this.filterVideoList}/> {val}</div>
								))}
								</div>
								
								<div className="video-section">
								<h6 className="modal-title">Select a video from below and then click Embed to add embed code to the note</h6>
								<div className="video-section-table">
								<table className="table table-bordered">
								<tbody>
								{filterVideo.map((val, i) => (
									<tr key={i}>
										<td className="v-list">{val.name}</td>
										<td className="v-list">{val.description}</td>
										<td className="v-list">{val.tags.join(', ')}</td>
										<td className="v-list">
											<button type="button" className="btn btn-primary btn-embed" onClick={()=>this.previewEmbedCode(val.embedCode)}>Preview</button>
										</td>
										
										<td className="v-list">
											<button type="button" className="btn btn-primary btn-embed" onClick={()=>this.inserEmbedCode(val.embedCode)}>Embed</button>
										</td>
									</tr>
								))}
								</tbody>
								</table>
								</div>
								</div>
								
							</div>
						</div>
					</div>
				</div>
				
				<div className="modal video-preview" id={'videoPreview'}>
					<div className="modal-dialog modal-video-pre">
						<div className="modal-content">
							<div id="preView" className="modal-body">
							</div>
						</div>
					</div>
				</div>
				
				<div className="modal" id={"qrCodePopup"} role="dialog">
					<div className="modal-dialog modal-lg custom-modal qr-code-modal">
						<div className="modal-content qr-code-modal">
							<div className="modal-header">
								<button type="button" className="close" data-dismiss="modal">&times;</button>
							</div>
							<div className="modal-body text-center">
								<div id="qr-code-img">
								{/* <img src={"https://api.qrserver.com/v1/create-qr-code/?size=150x150&data='"+this.state.copyQrCode+"'"} alt="QR" width="150" height="150" /> */}
								<img src={this.state.uniqueQrCode} alt="QR" width="150" height="150" />
								</div>
								<div className="qr-code-des">
									
									<div className="mr-3"><b>Workflow:</b> {this.state.workflowName}</div>
									<div className="mr-3"><b>Task Name:</b> {this.state.taskName}</div>
									<div className="qr-code-button">
									<button onClick={()=>this.copyQrImage(this.state.copyQrCode)} className="btn copy-code-btn" type="button"><i className="fa fa-copy" aria-hidden="true"></i></button>
									<button onClick={()=>this.saveQrImage(this.state.copyQrCode,this.state.taskName)} className="btn copy-code-btn" type="button"><i className="fa fa-download" aria-hidden="true"></i></button>
									<button onClick={()=>this.printQrImage(this.state.copyQrCode,this.state.taskName)} className="btn copy-code-btn" type="button"><i className="fa fa-print" aria-hidden="true"></i></button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				
				
				<table id="my-table" className="hidden-table" >
				  <tr>
					<th>Steps</th>
					<th>Responsible Role</th>
					<th>Responsible Person</th>
					<th>Date Stated</th>
					<th>Status</th>
					<th>Date Completed</th>
					<th>Initials</th>
				  </tr> 
					{taskList.map((val, i) => {
			
						let due_date = moment(val.due_date).format('DD-MM-YYYY');
						let start_date = moment(due_date, "DD-MM-YYYY").subtract('days', val.x_days_number);
						
						start_date =  moment(start_date).format('MM/DD/YYYY');
						
						let ndue_date = moment(val.due_date).format('MM/DD/YYYY');
						let checklist = [];
						let cht = val.checklist;
						if(cht){
							
							cht = cht.split(',');
							
							if(cht.length > 0){
								
								cht.map((vl, i) => {
									let sh = vl.split(':');
									checklist.push(sh[0]);
								})
							
							}
						}
						
						let taskListLen = taskList.length;
						
						let details = parse(val.details_note);
						
						return (
							<tbody>
							<tr>
								<th><b>{i+1+'. '}{' '+val.name}</b></th>
								<td>{val.role}</td>
								<td>{val.person}</td>
								<td>{start_date != 'Invalid date' ? start_date :''}</td>
								<td>{val.task_option}</td>
								<td>{ndue_date != 'Invalid date' ? ndue_date :''}</td>
								<td></td>
								
							</tr>
							<tr>
							<td colspan="6"><b>Details Note: </b>{details}</td>
							</tr>
							<tr>
							<td colspan="1">
							<p>Checklist:</p>
							<p>&nbsp;</p>
							{checklist.map((vl, i) => {
									return (
									<span><br />[ ] {vl}</span>
									);
							})}
							<p>&nbsp;</p>
							</td>
							<td colspan="4">
								Notes: 
							</td>
							</tr>
							<tr><td colspan="7">&nbsp;</td></tr>
							{taskListLen > i+1 ?
								<tr><td colspan="8">&nbsp;</td></tr>
							:null}
							</tbody>
						);
					})}
				  
				</table>
				
			</div>	
		);
	}
}
