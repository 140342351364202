import React, { Component } from 'react';
import $ from 'jquery';
import axios,{post,get} from 'axios';
import moment from 'moment';
import { ulid } from 'ulid';
import arrayMove from "./arrayMove";
import { CSVLink } from "react-csv";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Button, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';

export default class StartWorkFlow extends Component {
	
	constructor(props){
        super(props)
		this.state ={
			workflowList:[],
			workflowFilter:[],
			userData:[],
			catList:[],
			catSubList:[],
			authors:[],
			taskList:[],
			startWorkflowValues:[],
			filterAuthor:'',
			filterCategory:'',
			filterSubCategory:'',
			actionFilter:1,
			shareableFilter:'Y',
			addEditCategory:false,
			confirmOpen:false,
			confirmOpenActive:false,
			wId:'',
			cusCategoryName:'',
			addEditSubCategory:false,
			previewName:'',
			selectedWorkflowName:'',
			notShareable:'N',
			daysCount:0,
			page:0,
			rowsPerPage:10,
			startDate:dayjs(new Date()),
			/* Patient_Branch_ID: '4650',
			Branch_ID:  '4650',
			TreatingPractitioner_ID:  '12',
			Patient_ID:  '1047',
			Patient_FirstName:  'Joe',
			Patient_LastName:  'Chamberlain',
			Wip_ID:  '12456',
			GoToLinkType:  'Orthotic',
			Insurance_Company:  'BC/BS of Florida',
			Rx_ID:  '15634',
			Claim_ID:  '23112',
			Visit_ID:  '56511',
			InitiatingForm_ID:  'ECE9E8AF-2743-4BE6-BFF1-137EA6F739C9',
			Link_Url:  'https://Opieanywhere.com', */
			
			PatientBranchID: '',
			BranchID:  '',
			TreatingPractitionerID:  '',
			PatientID:  '',
			PatientFirstName:  '',
			PatientLastName:  '',
			WipID:  '',
			GoToLinkType:  '',
			InsuranceCompany:  '',
			RxID:  '',
			ClaimID:  '',
			VisitID:  '',
			InitiatingFormID:  '',
			LinkUrl:  '',
			VideoDocumentationFormID:  '',
			DigitalFabricationFormID:  '',
			PrescriptionID:  '',
			CodeJustificationDocListID:  '',
			ResponseID:  '',
			CodeSelectionDocListID:  '',
		}
		this.handleChange = this.handleChange.bind(this);
    }
	
	componentDidMount() {
		this.getTaskMasterUsers();
		this.getWorkflow();	
		this.getCategories();	
		this.loadScript();	
	}
	
	loadScript() {
		$(function() {
		  'use strict';
		  $('.js-menu-toggle').click(function(e) {
			var $this = $(this);
			if ( $('#wrapper').hasClass('toggled') ) {
				$('#wrapper').removeClass('toggled');
				$this.removeClass('active');
			} else {
				$('#wrapper').addClass('toggled');	
				$this.addClass('active');
			}
			e.preventDefault();
		  });
		});
	}
	
	handleChange = date => {
        this.setState({
          startDate: date,date
        });
    };
	
	getWorkflow=()=>{
		let ApiUrl = $('#ApiUrl').val();
		let that = this;
		let url = ApiUrl+'start-workflows';
		let data = [];
		axios.get(url)
        .then(response => {
            data = response.data;
			
			let role = localStorage.getItem('role');
			let user_id = localStorage.getItem('user_id');
			let assignCompany = localStorage.getItem('userCompany');
			
			let authors = [];
			let newData = [];
			data.map((val,i)=>{
				if(role == 'Admin'){
					if(val['user_id'] != user_id || val['company'] != assignCompany){
						return false;
					}
				}
				newData.push(val);
				authors.push(val.user_id);
			});
			let authorsIds = that.uniqueArray(authors);
			
			this.setState({workflowList:newData,authors:authorsIds});
			
			setTimeout(function(){
				that.dataFilter();
			}, 1000);
        })
	}
	
	getCategories=()=>{
		let ApiUrl = $('#ApiUrl').val();
		let that = this;
		let url = ApiUrl+'workflow-categories';
		let data = [];
		axios.get(url)
        .then(response => {
            data = response.data;
			this.setState({catList:data});
        })
	}
	
	getSubCategories=(id)=>{
		let ApiUrl = $('#ApiUrl').val();
		let that = this;
		let url = ApiUrl+'workflow-sub-categories/'+id;
		let data = [];
		axios.get(url)
        .then(response => {
            data = response.data;
			this.setState({catSubList:data});
        })
	}
	
	getTaskMasterUsers=() => {
		
		let user = localStorage.getItem('username');
		
		let ApiUrl = $('#ApiUrl').val();
		let url = ApiUrl+'authors';
		let data = [];
		axios.get(url)
        .then(response => {
			data = response.data;
			
			let userDt = [];
			
			data.map(function(val,n) {
				let user = [val.user_guid,val.user_name];
				userDt.push(user);
			})
			this.setState({userData:userDt});
			
		}).catch(error => {
			alert('error::'+ error);
		})
    }
	
	getValue=(event)=>{
		let name = event.target.name;
		let res = event.target.value;

		if(name == 'filterCategory' || name == 'wCategory'){
			this.getSubCategories(res);
		}
		
		this.setState({[event.target.name]:event.target.value});
		
		let that = this;
		setTimeout(function(){
			that.dataFilter();
		}, 1000);
	}
	
	dataFilter=() => {
		let workflowFilter = [];
		let that = this;
		this.state.workflowList.map(function(row,i) {
		
			if(that.state.filterCategory && that.state.filterCategory != row.category){
				return false;
			}
			
			if(that.state.filterSubCategory && that.state.filterSubCategory != row.subcategory){
				return false;
			}
			
			workflowFilter.push(row);
			
		});
		this.setState({workflowFilter,page:0});
	}
	
	uniqueArray(arr) {
		var a = [];
		for (var i=0, l=arr.length; i<l; i++)
			if (a.indexOf(arr[i]) === -1 && arr[i] !== '')
				a.push(arr[i]);
		return a;
	}
	

	StartWorkflow=() => {
		
		let data = this.state.startWorkflowValues;
		
		let startWorkflowValues = { 
			"WorkflowID":  data.WorkflowID,
			"FacilityID":  data.FacilityID,
			"PatientBranchID": this.state.PatientBranchID,
			"BranchID":  this.state.BranchID,
			"UserID":  data.UserID,
			"Username":  data.Username,
			"TreatingPractitionerID":  this.state.TreatingPractitionerID,
			"PatientID":  this.state.PatientID,
			"PatientFirstName":  this.state.PatientFirstName,
			"PatientLastName":  this.state.PatientLastName,
			"WipID":  this.state.WipID,
			"GoToLinkType":  this.state.GoToLinkType,
			"InsuranceCompany":  this.state.InsuranceCompany,
			"RxID":  this.state.RxID,
			"ClaimID":  this.state.ClaimID,
			"VisitID":  this.state.VisitID,
			"InitiatingFormID":  this.state.InitiatingFormID,
			"LinkUrl":  this.state.LinkUrl,
			
			"VideoDocumentationFormID":  this.state.VideoDocumentationFormID,
			"DigitalFabricationFormID":  this.state.DigitalFabricationFormID,
			"PrescriptionID":  this.state.PrescriptionID,
			"CodeJustificationDocListID":  this.state.CodeJustificationDocListID,
			"ResponseID":  this.state.ResponseID,
			"CodeSelectionDocListID":  this.state.CodeSelectionDocListID,
			"StartDate":  moment(this.state.startDate).format('YYYY-MM-DD'),
		};
		
		if(startWorkflowValues){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'workflow-initiate-v4';
			let formData = new FormData();
			formData.append('WorkflowID', startWorkflowValues['WorkflowID']);
			formData.append('FacilityID', startWorkflowValues['FacilityID']);
			formData.append('PatientBranchID', startWorkflowValues['PatientBranchID']);
			formData.append('BranchID', startWorkflowValues['BranchID']);
			formData.append('UserID', startWorkflowValues['UserID']);
			formData.append('Username', startWorkflowValues['Username']);
			formData.append('TreatingPractitionerID', startWorkflowValues['TreatingPractitionerID']);
			formData.append('PatientID', startWorkflowValues['PatientID']);
			formData.append('PatientFirstName', startWorkflowValues['PatientFirstName']);
			formData.append('PatientLastName', startWorkflowValues['PatientLastName']);
			formData.append('WipID', startWorkflowValues['WipID']);
			formData.append('Type', startWorkflowValues['GoToLinkType']);
			formData.append('InsuranceCompany', startWorkflowValues['InsuranceCompany']);
			formData.append('RxID', startWorkflowValues['RxID']);
			formData.append('ClaimID', startWorkflowValues['ClaimID']);
			formData.append('VisitID', startWorkflowValues['VisitID']);
			formData.append('InitiatingFormID', startWorkflowValues['InitiatingFormID']);
			formData.append('LinkUrl', startWorkflowValues['LinkUrl']);
			
			formData.append('VideoDocumentationFormID', startWorkflowValues['VideoDocumentationFormID']);
			formData.append('DigitalFabricationFormID', startWorkflowValues['DigitalFabricationFormID']);
			formData.append('PrescriptionID', startWorkflowValues['PrescriptionID']);
			formData.append('CodeJustificationDocListID', startWorkflowValues['CodeJustificationDocListID']);
			formData.append('ResponseID', startWorkflowValues['ResponseID']);
			formData.append('CodeSelectionDocListID', startWorkflowValues['CodeSelectionDocListID']);
			
			formData.append('StartDate', startWorkflowValues['StartDate']);

			axios({
				method: 'POST',
				url: url,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data',
				}
			})
			.then(response => {
				if(response.data){
					alert(response.data);
					this.setState({PatientBranchID:'',BranchID:'',TreatingPractitionerID:'',PatientID:'',PatientFirstName:'',PatientLastName:'',InsuranceCompany:'',RxID:'',ClaimID:'',VisitID:'',InitiatingFormID:'',LinkUrl:'',VideoDocumentationFormID:'',DigitalFabricationFormID:'',PrescriptionID:'',CodeJustificationDocListID:'',ResponseID:'',CodeSelectionDocListID:''});
				} 
			}).catch(error => {
				alert('error::'+ error);
			})
		}
	}

	handleChangePage = (event: unknown, newPage: number) => {
		this.setState({page:newPage});
	};

	handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({rowsPerPage:event.target.value,page:0});
	};
	
	workflowPopup=(data) => {
		
		let WorkflowID = data.id;
		let userName = 'Admin';
		let user_id = '01HZVPFZ3VJ4HEG0PRXHYB1MR8';
		let facility_id = '01G8GMMX092W5BKJNNNHCEEER1';
		this.state.userData.map(function(user,i) {
			if(user[0] == data.user_id){
				userName = user[1];
			}
		})

		let startWorkflowValues = { 
			"WorkflowID":  WorkflowID,
			"FacilityID":  data.facility_id != 'null' ? data.facility_id : facility_id,
			"PatientBranchID": this.state.PatientBranchID,
			"BranchID":  this.state.BranchID,
			"UserID":  data.user_id != 'null' ? data.user_id : user_id,
			"Username":  userName,
			"TreatingPractitionerID":  this.state.TreatingPractitionerID,
			"PatientID":  this.state.PatientID,
			"PatientFirstName":  this.state.PatientFirstName,
			"PatientLastName":  this.state.PatientLastName,
			"WipID":  this.state.WipID,
			"GoToLinkType":  this.state.GoToLinkType,
			"InsuranceCompany":  this.state.InsuranceCompany,
			"RxID":  this.state.RxID,
			"ClaimID":  this.state.ClaimID,
			"VisitID":  this.state.VisitID,
			"InitiatingFormID":  this.state.InitiatingFormID,
			"LinkUrl":  this.state.LinkUrl,
			"VideoDocumentationFormID":  this.state.VideoDocumentationFormID,
			"DigitalFabricationFormID":  this.state.DigitalFabricationFormID,
			"PrescriptionID":  this.state.PrescriptionID,
			"CodeJustificationDocListID":  this.state.CodeJustificationDocListID,
			"ResponseID":  this.state.ResponseID,
			"CodeSelectionDocListID":  this.state.CodeSelectionDocListID,
		};
		
		this.setState({startWorkflowValues});
	}
	
	render() {
		
		const {workflowList,workflowFilter,userData,catList,catSubList,authors,filterAuthor,filterCategory,filterSubCategory,actionFilter,shareableFilter,addEditCategory,cusCategoryName,taskList,notShareable,addEditSubCategory,cusSubCategoryName,startWorkflowValues} = this.state;
		
		//console.log('startWorkflowValues->',startWorkflowValues);
		
		let href = window.location.href.split('?')[0];
		
		let categoryOption = catList.map(function(val,i) {
			return (
				<option value={val.id} key={i}>{val.name}</option>
			);
		})
		
		let subcategoryOption = catSubList.map(function(val,i) {
			return (
				<option value={val.id} key={i}>{val.name}</option>
			);
		})
		
		
		const StyledTableCell = styled(TableCell)(({ theme }) => ({
		  [`&.${tableCellClasses.head}`]: {
			backgroundColor: '#e9e9e9',
			color: '#000;',
			fontWeight: 600,
			fontSize: 15,
		  },
		  [`&.${tableCellClasses.body}`]: {
			fontSize: 16,
		  },
		}));

		return (
			<div>
			<aside className="sidebar">
			  <div className="toggle toggle-btn">
				<a href="#" className="burger js-menu-toggle active" data-toggle="collapse" data-target="#main-navbar">
					  <span></span>
					</a>
			  </div>
			  <div className="side-inner align-items-end" >
				<div className="sidebar-content">
					<div className="nav-content">
						<h5 className="page-title">Workflow Filter</h5>
						<div className="panel-btn">
							<div className="input-group mb-3">
								<select className="form-control builder-filter" name="filterCategory" onChange={this.getValue}>
									<option value={''}>Select Category</option>
									{categoryOption}
								</select>
							</div>
							{catSubList.length > 0 ?
							<div className="input-group mb-3">
								<select className="form-control builder-filter" name="filterSubCategory" onChange={this.getValue}>
									<option value={''}>Select Subcategory</option>
									{subcategoryOption}
								</select>
							</div>
							:null}					
						</div>
						
					</div>
				</div>
			  </div>
			</aside>
			<div id="page-content-wrapper" className="workflow-manager">
				<div className="workflow-list">
					<div className="row">
						<div className="col-md-12 mb-4">
							<h4>Start a Workflow</h4>
						</div>
					</div>
					
				</div> 
				<Paper sx={{ width: '100%', overflow: 'hidden' }}>
				<TableContainer>
				  <Table size="small" aria-label="a dense table">
					<TableHead>
					  <TableRow>
						<StyledTableCell>Category</StyledTableCell>
						<StyledTableCell>Subategory</StyledTableCell>
						<StyledTableCell>Workflow Name</StyledTableCell>
						<StyledTableCell>Workflow GUID</StyledTableCell>
						
						<StyledTableCell>Start</StyledTableCell>
					  </TableRow>
					</TableHead>
					<TableBody>
					  {workflowFilter.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((row) => {
						  
						return (<TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
						  <TableCell>{row.categoryname}</TableCell>
						  <TableCell>{row.subcategoryname}</TableCell>
						  <TableCell>{row.name}</TableCell>
						  <TableCell>{row.uid}</TableCell>
						  
						  <TableCell><button onClick={()=>this.workflowPopup(row)} data-toggle="modal" data-target="#editWorkflow" type="button" className="btn"><img src={href+'/gotolink.png'} alt="edit" width="18" height="18"/></button></TableCell>
						</TableRow>)
					  })}
					</TableBody>
				  </Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[5, 10, 20, 50, 100]}
					component="div"
					count={workflowFilter.length}
					rowsPerPage={this.state.rowsPerPage}
					page={this.state.page}
					onPageChange={this.handleChangePage}
					onRowsPerPageChange={this.handleChangeRowsPerPage}
				  />
				</Paper>
				
				<div className="modal" id={"editWorkflow"} role="dialog">
					<div className="modal-dialog modal-lg custom-modal">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Workflow Initiate Details</h5>
								<button type="button" className="close" data-dismiss="modal">&times;</button>
							</div>
							<div className="modal-body edit-w">
								<div className="container start-wc">
									<div className="row">
										<div className="col-4">WorkflowID:</div>
										<div className="col-8">{startWorkflowValues.WorkflowID}</div>
									</div>
									<div className="row">
										<div className="col-4">FacilityID:</div>
										<div className="col-8">{startWorkflowValues.FacilityID}</div>
									</div>
									<div className="row">
										<div className="col-4">PatientBranchID:</div>
										<div className="col-8"><input className="form-control" name="PatientBranchID" value={this.state.PatientBranchID} type="text" onChange={this.getValue}/></div>
									</div>
									<div className="row">
										<div className="col-4">BranchID:</div>
										<div className="col-8"><input className="form-control" name="BranchID" value={this.state.BranchID} type="text" onChange={this.getValue}/></div>
									</div>
									<div className="row">
										<div className="col-4">UserID:</div>
										<div className="col-8">{startWorkflowValues.UserID}</div>
									</div>
									<div className="row">
										<div className="col-4">Username:</div>
										<div className="col-8">{startWorkflowValues.Username}</div>
									</div>
									
									<div className="row">
										<div className="col-4">TreatingPractitionerID:</div>
										<div className="col-8"><input className="form-control" name="TreatingPractitionerID" value={this.state.TreatingPractitionerID} type="text" onChange={this.getValue}/></div>
									</div>
									
									<div className="row">
										<div className="col-4">PatientID:</div>
										<div className="col-8"><input className="form-control" name="PatientID" value={this.state.PatientID} type="text" onChange={this.getValue}/></div>
									</div>
									
									<div className="row">
										<div className="col-4">PatientFirstName:</div>
										<div className="col-8"><input className="form-control" name="PatientFirstName" value={this.state.PatientFirstName} type="text" onChange={this.getValue}/></div>
									</div>
									
									<div className="row">
										<div className="col-4">PatientLastName:</div>
										<div className="col-8"><input className="form-control" name="PatientLastName" value={this.state.PatientLastName} type="text" onChange={this.getValue}/></div>
									</div>
									
									<div className="row">
										<div className="col-4">InsuranceCompany:</div>
										<div className="col-8"><input className="form-control" name="InsuranceCompany" value={this.state.InsuranceCompany} type="text" onChange={this.getValue}/></div>
									</div>
									
									<div className="row">
										<div className="col-4">RxID:</div>
										<div className="col-8"><input className="form-control" name="RxID" value={this.state.RxID} type="text" onChange={this.getValue}/></div>
									</div>
									
									<div className="row">
										<div className="col-4">ClaimID:</div>
										<div className="col-8"><input className="form-control" name="ClaimID" value={this.state.ClaimID} type="text" onChange={this.getValue}/></div>
									</div>
									<div className="row">
										<div className="col-4">VisitID:</div>
										<div className="col-8"><input className="form-control" name="VisitID" value={this.state.VisitID} type="text" onChange={this.getValue}/></div>
									</div>
									<div className="row">
										<div className="col-4">InitiatingFormID:</div>
										<div className="col-8"><input className="form-control" name="InitiatingFormID" value={this.state.InitiatingFormID} type="text" onChange={this.getValue}/></div>
									</div>
									
									<div className="row">
										<div className="col-4">LinkUrl:</div>
										<div className="col-8"><input className="form-control" name="LinkUrl" value={this.state.LinkUrl} type="text" onChange={this.getValue}/></div>
									</div>
									
									<div className="row">
										<div className="col-4">VideoDocumentationFormID:</div>
										<div className="col-8"><input className="form-control" name="VideoDocumentationFormID" value={this.state.VideoDocumentationFormID} type="text" onChange={this.getValue}/></div>
									</div>
									
									<div className="row">
										<div className="col-4">DigitalFabricationFormID:</div>
										<div className="col-8"><input className="form-control" name="DigitalFabricationFormID" value={this.state.DigitalFabricationFormID} type="text" onChange={this.getValue}/></div>
									</div>
									
									<div className="row">
										<div className="col-4">PrescriptionID:</div>
										<div className="col-8"><input className="form-control" name="PrescriptionID" value={this.state.PrescriptionID} type="text" onChange={this.getValue}/></div>
									</div>
									
									<div className="row">
										<div className="col-4">CodeJustificationDocListID:</div>
										<div className="col-8"><input className="form-control" name="CodeJustificationDocListID" value={this.state.CodeJustificationDocListID} type="text" onChange={this.getValue}/></div>
									</div>
									
									<div className="row">
										<div className="col-4">ResponseID:</div>
										<div className="col-8"><input className="form-control" name="ResponseID" value={this.state.ResponseID} type="text" onChange={this.getValue}/></div>
									</div>
									
									<div className="row">
										<div className="col-4">CodeSelectionDocListID:</div>
										<div className="col-8"><input className="form-control" name="CodeSelectionDocListID" value={this.state.CodeSelectionDocListID} type="text" onChange={this.getValue}/></div>
									</div>

									<div className="row">
										<div className="col-4">StartDate:</div>
										<div className="col-8">
										<LocalizationProvider dateAdapter={AdapterDayjs}>
										 <DatePicker 
											value={this.state.startDate}
											onChange={this.handleChange}
										  />
										</LocalizationProvider>
	
										</div>
									</div>
								</div>
							</div>
							<div className="modal-footer">
								<div className="popup-btn-com">
									<button type="button" className="btn btn-danger float-right" data-dismiss="modal">Close</button>
									<button type="button" onClick={()=>this.StartWorkflow()} className="btn btn-info float-right mr-1" data-dismiss="modal">Start</button>
								</div>
							</div>
						</div>
					</div>
				</div>
	
			</div>	
			</div>	
		);
	}
}
